define("lh4/components/ui/dates/datetime-range-picker", ["exports", "jquery", "lh4/enums/date-time-format", "lh4/enums/key-code", "lh4/utils/date-moment", "lh4/utils/no-op", "moment", "lh4/components/ui/dates/default-custom-options"], function (_exports, _jquery, _dateTimeFormat, _keyCode, _dateMoment, _noOp, _moment, _defaultCustomOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    classNames: ['ui', 'icon', 'input'],
    // Calendar Configuration
    autoAlign: true,
    calendarCount: 1,
    enableKeyboard: false,
    end: null,
    format: _dateTimeFormat.default.LOCALIZED_SHORT,
    maxDate: null,
    // we have to limit this as otherwise client's browser might hang up
    minDate: (0, _moment.default)().add(-100, 'y'),
    minuteSteps: 5,
    monthSwitcherFormat: 'MMM',
    onafterselect: _noOp.default,
    onBlur: _noOp.default,
    oneCalendarWidth: 340,
    onfirstselect: _noOp.default,
    onbeforeselect: function onbeforeselect() {
      return true;
    },
    onrangeselect: function onrangeselect(instance, _ref) {
      var startDate = _ref.startDate,
          endDate = _ref.endDate;
      // When defined range is selected
      // Update to the start of the first day and end of the last
      // day adjusting for our minuteSteps. Then update timepicker.
      instance.setStart(startDate.startOf('day'));
      instance.setEnd(endDate.endOf('day'));
      instance.updateTimePickerDisplay();
    },
    ontimechange: _noOp.default,
    picker: null,
    rangeOrientation: 'vertical',
    ranges: Ember.computed(function () {
      return (0, _dateMoment.getDefaultDateRanges)();
    }),
    reverseTimepickerArrows: true,
    showHeader: false,
    showTimePickers: true,
    start: null,
    verticalRangeWidth: 140,
    defaultStartTime: null,
    defaultEndTime: null,
    disabled: false,
    startOnMonday: false,
    customOptions: _defaultCustomOptions.default,
    prevMonthDataAttribute: '',
    nextMonthDataAttribute: '',
    monthSwitchDataAttribute: '',
    yearSwitchDataAttribute: '',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupPicker(); // adding check to see if we are adding data dog mertrics
      // for optional data attributes

      if (this['data-dd-action-name']) {
        this.picker.config.prevMonthDataAttribute = "data-dd-action-name=\"".concat(this['data-dd-action-name'], " Previous Month\"");
        this.picker.config.nextMonthDataAttribute = "data-dd-action-name=\"".concat(this['data-dd-action-name'], " Next Month\"");
        this.picker.config.monthSwitchDataAttribute = "data-dd-action-name=\"".concat(this['data-dd-action-name'], " Month Switch\"");
        this.picker.config.yearSwitchDataAttribute = "data-dd-action-name=\"".concat(this['data-dd-action-name'], " Year Switch\"");
      } else {
        this.picker.config.prevMonthDataAttribute = this.prevMonthDataAttribute;
        this.picker.config.nextMonthDataAttribute = this.nextMonthDataAttribute;
        this.picker.config.monthSwitchDataAttribute = this.monthSwitchDataAttribute;
        this.picker.config.yearSwitchDataAttribute = this.yearSwitchDataAttribute;
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var picker = this.picker,
          ranges = this.ranges,
          _this$customOptions = this.customOptions,
          customOptions = _this$customOptions === void 0 ? _defaultCustomOptions.default : _this$customOptions,
          start = this.start,
          end = this.end;

      if (!picker) {
        return;
      }

      if (!(start && end)) {
        picker.clearInput();
      } else {
        picker.setStart(start);
        picker.setEnd(end);
      }

      picker.config.customOptions = customOptions;
      picker.config.ranges = ranges || []; // update active ranges and

      picker.config.ranges.forEach(function (currentValue) {
        currentValue.selected = start === currentValue.startDate.format(_dateTimeFormat.default.LOCALIZED_SHORT) && end === currentValue.endDate.format(_dateTimeFormat.default.LOCALIZED_SHORT);
      }); // we have to redraw always for cases when date range is copy/pasted into input field,
      // and there's no easy way to detect if it was manually modified or via widget itself.

      picker.reDrawCalendars();
    },
    getDesktopPickerOptions: function getDesktopPickerOptions() {
      return {
        inline: this.inlineDesktop,
        oneCalendarWidth: this.oneCalendarWidth,
        startEmpty: !this.start || !this.end,
        rangeOrientation: this.rangeOrientation,
        showFooter: this.ranges && this.ranges.length > 0,
        ranges: this.ranges || []
      };
    },
    getInlinedPickerOptions: function getInlinedPickerOptions() {
      return {
        oneCalendarWidth: 395,
        inline: true,
        showFooter: false,
        startEmpty: false,
        startOnMonday: true
      };
    },
    getPickerSpecificOptions: function getPickerSpecificOptions(inline) {
      if (inline) {
        return this.getInlinedPickerOptions();
      }

      return this.getDesktopPickerOptions();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.picker) {
        this.picker.destroy();
      }
    },
    getOptions: function getOptions() {
      var options = this.getProperties('autoAlign', 'calendarCount', 'enableKeyboard', 'maxDate', 'minDate', 'minuteSteps', 'monthSwitcherFormat', 'onafterselect', 'format', 'showTimePickers', 'onfirstselect', 'onrangeselect', 'ontimechange', 'onbeforeshow', 'onbeforehide', 'reverseTimepickerArrows', 'showHeader', 'verticalRangeWidth', 'showButtons', 'startOnMonday', 'onbeforeselect');
      return _objectSpread(_objectSpread({}, options), this.getPickerSpecificOptions(this.inline));
    },
    setupPicker: function setupPicker() {
      var defaultStartTime = this.defaultStartTime,
          defaultEndTime = this.defaultEndTime,
          end = this.end,
          start = this.start,
          _this$customOptions2 = this.customOptions,
          customOptions = _this$customOptions2 === void 0 ? _defaultCustomOptions.default : _this$customOptions2;
      var inputElement = (0, _jquery.default)(this.element.querySelector('input.datetime-range-picker-input'));
      inputElement.calentim(this.getOptions());
      var picker = inputElement.data('calentim');
      picker.config.customOptions = customOptions;

      if (defaultStartTime) {
        picker.setStartTimeValue(parseInt(defaultStartTime.format('hh'), 10), parseInt(defaultStartTime.format('mm'), 10), defaultStartTime.format('a'));
      }

      if (defaultEndTime) {
        picker.setEndTimeValue(parseInt(defaultEndTime.format('hh'), 10), parseInt(defaultEndTime.format('mm'), 10), defaultEndTime.format('a'));
      }

      var startNormalized = start ? (0, _moment.default)(start, _dateTimeFormat.default.LOCALIZED_SHORT) : null;
      var endNormalized = end ? (0, _moment.default)(end, _dateTimeFormat.default.LOCALIZED_SHORT) : null; // we need to know if we are setting dates to past or future in order to get around some calentim issues

      if ((0, _moment.default)().isBefore(startNormalized)) {
        picker.setEnd(endNormalized);
        picker.setStart(startNormalized);
      } else {
        picker.setStart(startNormalized);
        picker.setEnd(endNormalized);
      }

      picker.config.ranges.forEach(function (currentValue) {
        var startFormatted = startNormalized ? startNormalized.format(_dateTimeFormat.default.LOCALIZED_SHORT) : null;
        var endFormatted = endNormalized ? endNormalized.format(_dateTimeFormat.default.LOCALIZED_SHORT) : null;
        currentValue.selected = startFormatted === currentValue.startDate.format(_dateTimeFormat.default.LOCALIZED_SHORT) && endFormatted === currentValue.endDate.format(_dateTimeFormat.default.LOCALIZED_SHORT);
      });
      this.set('picker', picker);
    },
    actions: {
      onFocus: function onFocus() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        var event = args[0];
        this.picker.showDropdown(event);

        if (this.onFocus) {
          this.onFocus.apply(this, args);
        }
      },
      onKeyDown: function onKeyDown(event) {
        if (event.keyCode === _keyCode.default.TAB || event.keyCode === _keyCode.default.ESC) {
          this.picker.hideDropdown(event);
        }
      }
    }
  });

  _exports.default = _default;
});