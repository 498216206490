define("lh4/controllers/authenticated/dashboard2", ["exports", "lh4/utils/widget-is-usable", "lh4/config/environment"], function (_exports, _widgetIsUsable, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    metrics: Ember.inject.service(),
    media: Ember.inject.service(),
    permissions: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    walkthrough: Ember.inject.service(),
    updateLog: Ember.inject.service(),
    session: Ember.inject.service(),
    queryParams: {
      activeLayoutSlug: 'layout'
    },
    readOnly: Ember.computed.bool('activeLayout'),
    activeLayout: Ember.computed('activeLayoutSlug', function getter() {
      return this.model.layouts.findBy('slug', this.activeLayoutSlug);
    }),
    adBannerUrl: _environment.default.adBannerUrl,
    branding: _environment.default.branding,
    brandNames: _environment.default.BrandNames,
    shouldShowTutorials: Ember.computed('walkthrough.data.dashboard2', 'updateLog.shouldShow', 'updateLog.allUpdates.isPending', function getter() {
      // don't open tutorials if user has unread updates in the updates modal
      return !this.walkthrough.data.dashboard2 && !this.updateLog.shouldShow && !this.updateLog.allUpdates.isPending;
    }),
    tutorialVideos: [{
      title: 'Getting started with the New Dashboard',
      id: '313401765'
    }, {
      title: 'How to move widgets',
      id: '313401644'
    }, {
      title: 'Adding a Gross or Net Widget',
      id: '313401717'
    }, {
      title: 'Widgets requiring setup',
      id: '313401813'
    }],
    numberOfColumns: Ember.computed('media.{isDesktop,isTablet,isMobile}', 'location.canViewLBM2', function getter() {
      if (this.get('media.isDesktop')) {
        return 'three';
      }

      if (this.get('media.isTablet')) {
        return 'two';
      }

      return 'one';
    }),
    layoutMode: false,
    order: undefined,
    shiftWidget: function shiftWidget(oldIndex, newIndex) {
      var widget = this.get('order')[oldIndex];
      this.get('order').removeAt(oldIndex);
      this.get('order').insertAt(newIndex, widget);
    },
    unusableWidgets: Ember.computed('model.locations.[]', 'model.widgets.[]', 'order.[]', function getter() {
      var _this = this;

      var unusableWidgets = [];

      if (!this.readOnly) {
        this.order.forEach(function (widget, index) {
          var widgetInfo = _this.model.widgets.findBy('slug', widget.slug);

          if (widgetInfo) {
            if (!(0, _widgetIsUsable.default)(widgetInfo, _this.model.locations)) {
              unusableWidgets.push(index);
            }
          } else {
            // user somehow has a widget that doesn't exist on the backend
            unusableWidgets.push(index);
          }
        });
      }

      return unusableWidgets;
    }),
    trackEventDashboardUpdated: function trackEventDashboardUpdated(type, widgetName) {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Dashboard Updated',
        'Update Type': type,
        'Widget Name': widgetName
      });
    },
    actions: {
      saveOrder: function saveOrder() {
        var _this2 = this;

        this.set('model.preferences.dashboardOrder', this.get('order'));
        this.get('model.preferences').save().then(function () {
          _this2.set('saveFailed', false);
        }).catch(function () {
          _this2.set('saveFailed', true);
        });
      },
      toggleOldDashboard: function toggleOldDashboard() {
        this.set('model.preferences.oldDashboard', true);
        this.get('model.preferences').save();
        this.transitionToRoute('authenticated.dashboard');
      },
      toggleLayoutMode: function toggleLayoutMode() {
        // save layout when exiting layout mode
        if (this.get('layoutMode')) {
          this.send('saveOrder');
        }

        this.toggleProperty('layoutMode');
      },
      shiftWidgetLeft: function shiftWidgetLeft(index) {
        this.shiftWidget(index, index - 1);
      },
      shiftWidgetRight: function shiftWidgetRight(index) {
        this.shiftWidget(index, index + 1);
      },
      addWidget: function addWidget(widget) {
        this.trackEventDashboardUpdated('Widget Added', widget.slug);
        this.get('order').pushObject(widget);
      },
      removeWidget: function removeWidget(index) {
        this.trackEventDashboardUpdated('Widget Deleted', this.get('order')[index].slug);
        this.get('order').removeAt(index);
      },
      removeUnusableWidgets: function removeUnusableWidgets() {
        var _this3 = this;

        // remove from end to beginning so indexes don't change
        this.unusableWidgets.reverse().forEach(function (index) {
          _this3.send('removeWidget', index);
        });
        this.send('saveOrder');
      },
      closeUnusableWidgetsMessage: function closeUnusableWidgetsMessage() {
        this.set('unusableWidgetsMessageClosed', true);
      },
      setLayout: function setLayout(layout) {
        if (layout) {
          this.setProperties({
            layoutMode: false,
            activeLayoutSlug: layout.slug,
            order: layout.widgets
          });
        } else {
          var adjustedDashboardOrder = [];

          if (this.can.can('manage workforce for echo-pro/workforce')) {
            var currentDashboardOrder = this.model.preferences.get('dashboardOrder');
            adjustedDashboardOrder = currentDashboardOrder.filter(function (singleWidget) {
              return singleWidget.slug !== 'labor-statistics';
            });
            this.setProperties({
              activeLayoutSlug: undefined,
              order: adjustedDashboardOrder
            });
          } else {
            this.setProperties({
              activeLayoutSlug: undefined,
              order: this.model.preferences.dashboardOrder
            });
          }
        }
      }
    }
  });

  _exports.default = _default;
});