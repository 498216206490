define("lh4/models/echo-pro/accounting/surcharge", ["exports", "ember-data", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/has-many-ids", "lh4/utils/belongs-to-id", "lh4/utils/is-positive-decimal-of-length", "lh4/utils/is-positive-percentage", "lh4/utils/is-positive-decimal-or-zero", "lh4/utils/is-positive-percentage-or-zero"], function (_exports, _emberData, _attr, _relationships, _emberCpValidations, _modelCloneable, _hasManyIds, _belongsToId, _isPositiveDecimalOfLength, _isPositivePercentage, _isPositiveDecimalOrZero, _isPositivePercentageOrZero) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Surcharge name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 63,
        description: 'Surcharge name length'
      })]
    },
    value: {
      description: 'Value',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)(function (value, options, model) {
        var isManagedByCorp = model.get('corporation.isMenuManagedByCorporate');
        var isDecimal = isManagedByCorp ? (0, _isPositiveDecimalOrZero.default)(value, 9) : (0, _isPositiveDecimalOfLength.default)(value, 9);
        var isPercentage = isManagedByCorp ? (0, _isPositivePercentageOrZero.default)(value, 2) : (0, _isPositivePercentage.default)(value, 2);
        return model.get('isFlatAmount') ? isDecimal : isPercentage;
      }, {
        dependentKeys: ['isFlatAmount']
      })]
    },
    itemIds: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.appliesTo', function getter() {
        return this.model.get('appliesTo') !== 'menu-item';
      }),
      message: 'At least one item should be added when Surcharge is applied to Menu Items'
    }),
    orderTypeId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.appliesTo', function getter() {
        return this.model.get('appliesTo') !== 'order-type';
      })
    }),
    taxSetId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed('model.appliesTo', function getter() {
        return this.model.get('appliesTo') !== 'order-type';
      })
    })
  });

  var _default = _emberData.default.Model.extend(Validations, _modelCloneable.default, {
    corporation: Ember.inject.service(),
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    name: (0, _attr.default)('string'),
    value: (0, _attr.default)('number'),
    isFlatAmount: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    useGross: (0, _attr.default)('boolean'),
    gratuity: (0, _attr.default)('boolean'),
    isPaidTipsFromDrawer: (0, _attr.default)('boolean'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    items: (0, _relationships.hasMany)('echo-pro/item'),
    orderType: (0, _relationships.belongsTo)('echo-pro/order-type'),
    taxSet: (0, _relationships.belongsTo)('echo-pro/accounting/tax-set'),
    itemIds: Ember.computed('items', (0, _hasManyIds.default)('items', 'echo-pro/item')),
    orderTypeId: Ember.computed('orderType', (0, _belongsToId.default)('orderType', 'echo-pro/order-type')),
    taxSetId: Ember.computed('taxSet', (0, _belongsToId.default)('taxSet', 'echo-pro/accounting/tax-set')),
    appliesTo: Ember.computed('items', 'orderType', 'taxSet', function getter() {
      if (this.itemIds.length) {
        return 'menu-item';
      }

      if (this.orderTypeId || this.taxSetId) {
        return 'order-type';
      }

      return 'none';
    })
  });

  _exports.default = _default;
});