define("lh4/controllers/authenticated/internet-payments/reporting/reports", ["exports", "lh4/controllers/authenticated/reporting/reporting", "lh4/enums/date-range-title", "lh4/enums/date-time-format", "lh4/enums/drill-down-report-error-codes", "lh4/enums/ip-pagination-limit", "lh4/enums/mlm-report-types", "lh4/enums/products", "lh4/utils/date-moment", "lh4/utils/lbm2", "lh4/utils/permissions", "moment"], function (_exports, _reporting, _dateRangeTitle, _dateTimeFormat, _drillDownReportErrorCodes, _ipPaginationLimit, _mlmReportTypes, _products, _dateMoment, _lbm, _permissions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var DATE_FORMAT = 'YYYY-MM-DD';
  var FULL_DATE_FORMAT = 'MM/DD/YYYY hh:mm A';

  var _default = _reporting.default.extend({
    queryParams: ['dates.startsAt', 'dates.startsAtTime', 'dates.endsAt', 'dates.endsAtTime', 'limit', 'offset', {
      reportType: 'report',
      generate: 'generate'
    }, 'selectedLocations', 'currentLocationsGroupId'],
    allowedLocations: Ember.computed('permissions', function allowedLocations() {
      var _this = this;

      var locations = this.store.peekAll('location').filter(function (location) {
        return _this.permissions.reportLocations.includes(Number(location.id));
      });
      return locations.filter(function (location) {
        return (0, _lbm.default)(location);
      }).map(function (_ref) {
        var id = _ref.id;
        return _this.store.peekRecord('location', id);
      }).sortBy('name');
    }),
    reportTypes: Ember.computed('model.reportTypes', function reportTypes() {
      var _this$model$reportTyp;

      return (_this$model$reportTyp = this.model.reportTypes) === null || _this$model$reportTyp === void 0 ? void 0 : _this$model$reportTyp[_products.default.INTERNET_PAYMENTS];
    }),
    location: Ember.inject.service(),
    groupedReportTypes: Ember.computed('reportTypes', 'model.preGeneratedReport', function groupedReportTypes() {
      var _this2 = this;

      var filteredReportTypes = this.reportTypes.filter(function (report) {
        return !(report.name === 'Transactions' && _this2.can.cannot('view transactions in general'));
      });
      var reportTypeCategories = filteredReportTypes.reduce(function (group, reportType) {
        var _grouped$category;

        var category = reportType.category;
        var grouped = group;
        grouped[category] = (_grouped$category = grouped[category]) !== null && _grouped$category !== void 0 ? _grouped$category : [];
        grouped[category].push(reportType);
        return grouped;
      }, {});
      return Object.entries(reportTypeCategories).map(function (reportTypeCategory) {
        var _reportTypeCategory = _slicedToArray(reportTypeCategory, 2),
            title = _reportTypeCategory[0],
            reports = _reportTypeCategory[1];

        return {
          title: title.capitalize(),
          reports: reports.sortBy('name')
        };
      });
    }),
    reportApi: Ember.inject.service('reports/internet-payment-report-api'),
    reportType: null,
    router: Ember.inject.service(),
    can: Ember.inject.service(),
    media: Ember.inject.service(),
    isModalVisible: false,
    rangeTitle: 'Today',
    limit: _ipPaginationLimit.default.LIMIT,
    offset: 0,
    filteredField: 'status',
    dates: {
      startsAt: (0, _moment.default)().startOf('day'),
      endsAt: (0, _moment.default)().endOf('day')
    },
    reportData: {},
    selectedLocations: [],
    currentLocationsGroupId: '',
    reportError: Ember.computed('error', function reportError() {
      var _this$error$details;

      if (!this.error) {
        return null;
      }

      switch ((_this$error$details = this.error.details) === null || _this$error$details === void 0 ? void 0 : _this$error$details.code) {
        case _drillDownReportErrorCodes.default.NODE_NOT_FOUND:
          {
            return {
              header: 'Report has expired',
              message: 'Please generate the report again.'
            };
          }

        default:
          return this.error;
      }
    }),
    setDates: function setDates(startMoment, endMoment) {
      this.setProperties({
        dates: {
          startsAt: startMoment.format(DATE_FORMAT),
          endsAt: endMoment.format(DATE_FORMAT)
        }
      });
    },
    updateDates: function updateDates(startDate, endDate) {
      var formatStart = _moment.default.utc(startDate).format(FULL_DATE_FORMAT);

      var formatEnd = _moment.default.utc(endDate).format(FULL_DATE_FORMAT);

      this.setProperties({
        newDates: {
          start: (0, _moment.default)(formatStart, _dateTimeFormat.default.LOCALIZED_SHORT).startOf('day'),
          end: (0, _moment.default)(formatEnd, _dateTimeFormat.default.LOCALIZED_SHORT).endOf('day')
        }
      });
    },
    determineTimeRange: function determineTimeRange(dates) {
      return this.reportApi.determineTimeRange(dates, this.location.getLocationTimezone());
    },
    isLocationFilterVisible: Ember.computed('location.treeNodeDefinitions', 'reportType', function isLocationFilterVisible() {
      return (0, _lbm.default)(this.location.model) && this.locationTreeNodeDefinitions.filter(function (node) {
        return node.parentId && node.parentId !== 'root';
      }).length > 1 && _mlmReportTypes.default.includes(this.reportType);
    }),
    locationGroups: Ember.computed('model.locationGroups', function locationGroups() {
      var _this3 = this;

      return this.model.locationGroups.filter(function (group) {
        return group.locations.length && group.isActive;
      }).map(function (group) {
        return {
          name: group.name,
          id: group.id,
          isActive: group.isActive,
          locations: group.locations.map(function (id) {
            var _this3$store$peekReco;

            return {
              id: id,
              usesShift4PaymentProcessor: (_this3$store$peekReco = _this3.store.peekRecord('location', id)) === null || _this3$store$peekReco === void 0 ? void 0 : _this3$store$peekReco.usesShift4PaymentProcessor
            };
          }).filter(function (location) {
            return _this3.permissions.hasPermission(_permissions.default.REPORTING.REPORTS, location.id) && location.usesShift4PaymentProcessor;
          })
        };
      }).sort(function (a, b) {
        return a.name.toString().localeCompare(b.name.toString(), undefined, {
          numeric: true
        });
      });
    }),
    locationTreeNodeDefinitions: Ember.computed('location.treeNodeDefinitions', function locationTreeNodeDefinitions() {
      var _this4 = this;

      var parentIdsFromChildren = [];

      function canViewLocationNode(node) {
        return (0, _lbm.default)(node) && this.permissions.hasPermission(_permissions.default.REPORTING.REPORTS, node.id) && node.usesShift4PaymentProcessor;
      }

      return this.location.treeNodeDefinitions.map(function (node) {
        // need to figure out what parent nodes have children in tree to not show parents without children
        // and filter them out downstream
        if (node.parentId && canViewLocationNode.call(_this4, node) && !parentIdsFromChildren.includes(node.parentId)) {
          parentIdsFromChildren.push(node.parentId);
        }

        if (node.parentId) {
          return _objectSpread(_objectSpread({}, node), {}, {
            name: "#".concat(node.merchantId, " - ").concat(node.name)
          });
        }

        return _objectSpread(_objectSpread({}, node), {}, {
          parentId: 'root'
        });
      }).filter(function (node) {
        return node.parentId === 'root' && parentIdsFromChildren.includes(node.id) || canViewLocationNode.call(_this4, node);
      });
    }),

    /**
     * Set up ranges and maxDate constraints
     *
     * This is a workaround because of the way we are setting global timezone. Any use of moment should
     * happen after the default moment timezone is set.
     *
     * This method is meant to be called in the 'setupController' hook. By the time the hook is called,
     * the default moment timezone should be set to the correct value (see '_afterLocationLoaded' function).
     */
    setup: function setup() {
      if (!this.ranges) {
        this.ranges = (0, _dateMoment.generateDateRanges)([_dateMoment.Period.TODAY, _dateMoment.Period.YESTERDAY, _dateMoment.Period.THIS_WEEK, _dateMoment.Period.LAST_WEEK, _dateMoment.Period.THIS_MONTH, _dateMoment.Period.LAST_MONTH, _dateMoment.Period.THIS_YEAR], {
          weekStart: 1
        });
      }

      if (!this.maxDate) {
        this.maxDate = (0, _moment.default)().endOf('day');
      }

      if (this.selectedReport.name) {
        this.updateDates(this.dates.startsAt, this.dates.endsAt);
        this.send('generateReport');
      }
    },
    reportTypeChanged: Ember.observer('reportType', function callback() {
      if (!this.reportType) {
        this.send('resetReport');
      }
    }),
    actions: {
      selectReportPeriodForInternetPayments: function selectReportPeriodForInternetPayments(name, firstDate, secondDate, rangeTitle) {
        var startsAt = firstDate;
        var endsAt = secondDate; // In IP Reports, we set a default range period on initial date picker
        // This will also set default range period when we select a report which has different requirements
        // Ex: Date range to single date report

        if (!firstDate) {
          var range = this.ranges.find(function (item) {
            return item.title === _dateRangeTitle.DATE_RANGE_TITLE.TODAY;
          });
          startsAt = range.startDate.format(DATE_FORMAT);
          endsAt = range.endDate.format(DATE_FORMAT);
        }

        return this.send('selectReportPeriod', name, startsAt, endsAt, rangeTitle);
      },
      generateFromCard: function generateFromCard(type, params) {
        var _params$datePickerOpt;

        var rangeToChoose = (_params$datePickerOpt = params.datePickerOptions) !== null && _params$datePickerOpt !== void 0 && _params$datePickerOpt.singleDate ? _dateRangeTitle.DATE_RANGE_TITLE.TODAY : _dateRangeTitle.DATE_RANGE_TITLE.WEEK.THIS;
        var range = this.ranges.find(function (item) {
          return item.title === rangeToChoose;
        }); // TODO: LH-18458 - Find better way to get report queryParam to set.
        // For some reason it doesnt work in setProperties

        this.router.replaceWith('authenticated.internet-payments.reporting.reports', {
          queryParams: {
            report: type
          }
        });
        this.setProperties({
          dates: {
            startsAt: range.startDate.format(DATE_FORMAT),
            endsAt: range.endDate.format(DATE_FORMAT)
          },
          newDates: {
            start: (0, _moment.default)(range.startDate).format(FULL_DATE_FORMAT),
            end: (0, _moment.default)(range.endDate).format(FULL_DATE_FORMAT)
          },
          offset: 0
        });
        this.send('generateReport');
      },
      onFilterChange: function onFilterChange(_ref2) {
        var limit = _ref2.limit,
            offset = _ref2.offset;
        this.setProperties({
          limit: limit,
          offset: offset
        });
        this.send('generateReport');
      },
      onMobileRangeChange: function onMobileRangeChange(rangeTitle, start, end) {
        var startDate = start;
        var endDate = end;
        this.set('rangeTitle', rangeTitle);

        if (!startDate) {
          startDate = (0, _moment.default)();
          endDate = (0, _moment.default)();
        }

        if ((0, _dateRangeTitle.isCustom)(rangeTitle)) {
          this.setDates(startDate, endDate);
        }
      },
      onRangeSelect: function onRangeSelect() {
        return false;
      },
      onFirstSelect: function onFirstSelect(instance) {
        instance.setMinDate(null);
        instance.setMaxDate((0, _moment.default)().endOf('day'));
      },
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        // Reset minDate and maxDate so a new selection can be made
        instance.setMinDate(null);
        instance.setMaxDate((0, _moment.default)().endOf('day'));
        instance.hideDropdown();
        this.setDates(startDate, endDate);
      },
      // TODO: LH-21801, extract or remove this copy pasta
      onDesktopBlur: function onDesktopBlur(_ref3) {
        var view = _ref3.view,
            target = _ref3.target;
        var instance = view.$(target).data('calentim');
        var parts = target.value.split('-');
        var inputStart = (0, _moment.default)(parts[0].trim(), instance.options.format);
        var inputEnd = (0, _moment.default)(parts[1].trim(), instance.options.format); // validate dates entered and fallback to previous values if they're invalid

        var start = inputStart.isValid() ? inputStart.startOf('day') : instance.config.startDate;
        var end = inputEnd.isValid() ? inputEnd.endOf('day') : instance.config.endDate; // nothing changed, exit early

        if (start.isSame(instance.config.startDate) && end.isSame(instance.config.endDate)) {
          return instance.updateInput();
        }

        var maxDate = (0, _moment.default)().endOf('day'); // if start or end is after maxDate, set to today

        if (start.isAfter(maxDate)) {
          start = maxDate.clone().startOf('day');
        }

        if (end.isAfter(maxDate)) {
          end = maxDate;
        } // if start is after end, set start to the same day


        if (start.isAfter(end)) {
          start = end.clone().startOf('day');
        } // remember ranges to check later if active range changed and deselect it


        var rangesBefore = JSON.stringify(instance.config.ranges); // update active ranges

        instance.config.ranges.forEach(function (aRange) {
          var range = aRange;
          range.selected = range.startDate.isSame(start, 'day') && range.endDate.isSame(end, 'day');
        });
        instance.setStart(start);
        instance.setEnd(end);
        instance.updateInput();
        this.setDates(start, end); // checks if current range changed and deselects
        // later is used, because user can blur by clicking on new range button

        Ember.run.later(function () {
          // ranges changed, unselect previous active range
          if (JSON.stringify(instance.config.ranges) !== rangesBefore) {
            instance.reDrawCalendars();
          }
        }, 200);
        return false;
      },
      createSubscription: function createSubscription(reportTitle) {
        this.router.transitionTo('authenticated.internet-payments.reporting.subscriptions.create', {
          queryParams: {
            reportTitle: reportTitle
          }
        });
      },
      onPreGeneratedReports: function onPreGeneratedReports(isPreGeneratedReports, selectedReportType, event) {
        if (isPreGeneratedReports) {
          var category = event[0].dataset.category;
          this.set('reportType', selectedReportType);
          this.router.transitionTo('authenticated.internet-payments.reporting.reports.pre-generated', {
            queryParams: {
              reportName: selectedReportType,
              reportCategory: category,
              offset: 0
            }
          });
        } else {
          this.router.transitionTo('authenticated.internet-payments.reporting.reports', {
            queryParams: {
              offset: 0
            }
          });
        }
      },
      downloadReport: function downloadReport(url) {
        var a = document.createElement('a');
        a.href = url;
        a.click();
        this.send('close');
      },
      close: function close() {
        this.set('isModalVisible', false);
      },
      clickOnGenerateReport: function clickOnGenerateReport() {
        this.set('offset', 0);
        this.send('generateReport');
      },
      handleLocationGroupClick: function handleLocationGroupClick(locations, groupId) {
        this.set('currentLocationsGroupId', groupId);
        this.set('selectedLocations', locations.map(function (location) {
          return String(location.id);
        }));
      },
      resetLocationsFilter: function resetLocationsFilter() {
        var _this$selectedLocatio;

        this.set('currentLocationsGroupId', ''); // need this check because cant set default locationId from location service for initial transition
        // because the location service model isn't resolved yet when the QPs are getting set

        if ((_this$selectedLocatio = this.selectedLocations) !== null && _this$selectedLocatio !== void 0 && _this$selectedLocatio.length) {
          this.set('selectedLocations', [this.location.model.id]);
        }
      },
      applyLocationsSelections: function applyLocationsSelections(selectedLocationIds) {
        this.set('currentLocationsGroupId', '');
        this.set('selectedLocations', selectedLocationIds);
      }
    }
  });

  _exports.default = _default;
});