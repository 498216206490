define("lh4/models/echo-pro/tax-rule", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations"], function (_exports, _model, _attr, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TAX_RULE_TYPES = _exports.operators = _exports.ruleTypes = _exports.default = void 0;
  var TAX_RULE_TYPES = {
    PRICE: 'Price',
    QUANTITY: 'Quantity'
  };
  _exports.TAX_RULE_TYPES = TAX_RULE_TYPES;
  var ruleTypes = [{
    id: TAX_RULE_TYPES.QUANTITY,
    name: TAX_RULE_TYPES.QUANTITY
  }, {
    id: TAX_RULE_TYPES.PRICE,
    name: TAX_RULE_TYPES.PRICE
  }];
  _exports.ruleTypes = ruleTypes;
  var operators = [{
    id: '>=',
    name: 'Greater than or equal to'
  }, {
    id: '<=',
    name: 'Less than or equal to'
  }];
  _exports.operators = operators;
  var Validations = (0, _emberCpValidations.buildValidations)({
    ruleType: {
      description: 'Rule Type',
      validators: [(0, _emberCpValidations.validator)(function (value) {
        return ruleTypes.some(function (type) {
          return type.id === value;
        }) || 'Incorrect Rule Type';
      })]
    },
    operator: {
      description: 'Operator',
      validators: [(0, _emberCpValidations.validator)(function (value) {
        return operators.some(function (operator) {
          return operator.id === value;
        }) || 'Incorrect Operator';
      })]
    },
    value: {
      description: 'Value',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        positive: true,
        gte: 0,
        lte: 99999
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    guid: (0, _attr.default)('string'),
    taxGuid: (0, _relationships.belongsTo)('echo-pro/tax'),
    locationId: (0, _attr.default)('number'),
    ruleType: (0, _attr.default)('string'),
    ruleTypes: ruleTypes.map(function (_ref) {
      var id = _ref.id,
          name = _ref.name;
      return {
        name: name,
        value: id
      };
    }),
    operator: (0, _attr.default)('string'),
    operators: operators.map(function (_ref2) {
      var id = _ref2.id,
          name = _ref2.name;
      return {
        name: name,
        value: id
      };
    }),
    value: (0, _attr.default)('number'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});