define("lh4/components/ui/form-fields/time-picker-field", ["exports", "lh4/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    classNames: ['time-picker-field'],
    dataTest: 'form-field-time-picker-field',
    init: function init() {
      this._super.apply(this, arguments);

      this.value = this.changeset.get(this.property);
    }
  });

  _exports.default = _default;
});