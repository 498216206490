define("lh4/constants/launch-control/modules/kiosk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.KIOSK_MODEL = _exports.COMPLETE_KIOSK_ORDER_STATUSES = _exports.PENDING_KIOSK_ORDER_STATUSES = void 0;
  var PENDING_KIOSK_ORDER_STATUSES = ['Processing', 'Shipped'];
  _exports.PENDING_KIOSK_ORDER_STATUSES = PENDING_KIOSK_ORDER_STATUSES;
  var COMPLETE_KIOSK_ORDER_STATUSES = ['Canceled', 'Complete'];
  _exports.COMPLETE_KIOSK_ORDER_STATUSES = COMPLETE_KIOSK_ORDER_STATUSES;
  var KIOSK_MODEL = 'ST POS Kiosk';
  _exports.KIOSK_MODEL = KIOSK_MODEL;
});