define("lh4/adapters/launch-control/module", ["exports", "lh4/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    primaryKey: 'moduleId',
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      var locationId = snapshot.adapterOptions.locationId;
      return "".concat(this.host, "/").concat(this.namespace, "/status-control/").concat(locationId, "/modules");
    }
  });

  _exports.default = _default;
});