define("lh4/serializers/echo-pro/discount", ["exports", "ember-data", "moment", "lh4/serializers/application"], function (_exports, _emberData, _moment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    location: Ember.inject.service(),
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      var replace = payloadKey.replace("".concat(payloadKey), "echo-pro/".concat(payloadKey));
      return this._super(replace);
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (parseInt(json.mode, 10) === 1) {
        json.value *= 100;
      }

      json.value = Number.parseFloat(json.value).toFixed(2);

      if (json.items) {
        json.items = json.items.mapBy('guid');
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      var normalized = this._super.apply(this, [modelClass, json].concat(args));

      if (json.startTime) {
        normalized.data.attributes.startTime = (0, _moment.default)(json.startTime);
      }

      if (json.endTime) {
        normalized.data.attributes.endTime = (0, _moment.default)(json.endTime);
      }

      return normalized;
    },
    serialize: function serialize() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      var json = this._super.apply(this, args);

      json.value = Number.parseFloat(json.value).toFixed(2);

      if (parseInt(json.mode, 10) === 1) {
        json.value /= 100;
      }

      if (args[0].record.startTime) {
        json.startTime = (0, _moment.default)(args[0].record.startTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      }

      if (args[0].record.endTime) {
        json.endTime = (0, _moment.default)(args[0].record.endTime).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      }

      return json;
    }
  });

  _exports.default = _default;
});