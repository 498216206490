define("lh4/authenticators/jwt-custom", ["exports", "ember-simple-auth-token/authenticators/jwt", "moment", "lh4/config/environment", "lh4/enums/microfrontend-event-keys"], function (_exports, _jwt, _moment, _environment, _microfrontendEventKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _jwt.default.extend({
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    logger: Ember.inject.service(),
    metrics: Ember.inject.service(),
    invalidate: function invalidate() {
      var _this = this;

      return this._super.apply(this, arguments).then(function () {
        return _this.ajax.post('/api/v1/auth/logout').then(function () {
          _this.metrics.invoke('reset', 'MixpanelCustom', {});
        });
      }).catch(function () {
        return Ember.RSVP.Promise.resolve();
      });
    },
    authenticate: function authenticate() {
      var _this2 = this;

      this.metrics.invoke('reset', 'MixpanelCustom', {});
      return this._super.apply(this, arguments).then(function (result) {
        var data = result;
        data.token = data[_this2.tokenPropertyName];

        var tokenData = _this2.getTokenData(data.token);

        var appShell = Ember.getOwner(_this2).lookup('appShell:main');

        if (appShell) {
          appShell.publish(_microfrontendEventKeys.default.AUTH, _objectSpread(_objectSpread({}, data), tokenData));
        }

        return _this2.ajax.request("/api/v1/accounts/".concat(tokenData.sub, "/preferences"), {
          type: 'GET',
          headers: {
            'X-Access-Token': data.token
          }
        }).then(function (prefObj) {
          _this2.invokeGoogleAnalytics(data);

          _this2.invokeMixpanel(data);

          if (!prefObj.preferences.timeZone) {
            var timeZone = _moment.default.tz.guess(); // Needed to reassign null property
            // eslint-disable-next-line no-param-reassign


            prefObj.preferences.timeZone = timeZone;

            _this2.ajax.request("/api/v1/accounts/".concat(tokenData.sub, "/preferences"), {
              type: 'PUT',
              headers: {
                'X-Access-Token': data.token
              },
              data: _objectSpread(_objectSpread({}, prefObj.preferences), {}, {
                timeZone: timeZone
              })
            });
          }

          return data;
        }).catch(function (err) {
          _this2.logger.log("There was an error getting timeZone preferences on refresh: ".concat(err, " "));

          return data;
        });
      });
    },
    getAuthenticateData: function getAuthenticateData(credentials) {
      var authentication = {
        email: credentials.identification,
        password: credentials.password,
        otpCode: credentials.otpCode,
        recoveryCode: credentials.recoveryCode
      };

      if (typeof credentials.currentEmail === 'string') {
        authentication.currentEmail = credentials.currentEmail;
      }

      return authentication;
    },
    invokeGoogleAnalytics: function invokeGoogleAnalytics(data) {
      try {
        // attach user analytics id to every event
        // https://github.com/poteto/ember-metrics/blob/master/addon/metrics-adapters/google-analytics.js#L66
        this.metrics.identify('GoogleAnalyticsWithCustomDimensions', {
          distinctId: data.aId
        }); // attach custom dimensions to user session

        this.metrics.invoke('dimensions', 'GoogleAnalyticsWithCustomDimensions', {
          dimension1: data.aId,
          dimension2: _environment.default.branding.name,
          dimension3: new Date().getTime()
        });
      } catch (e) {// do nothing
      }
    },
    invokeMixpanel: function invokeMixpanel(data) {
      try {
        this.metrics.identify('MixpanelCustom', {
          distinctId: data.aId,
          $email: data.email,
          $name: "".concat(data.firstName, " ").concat(data.lastName)
        });
      } catch (e) {// do nothing
      }
    }
  });

  _exports.default = _default;
});