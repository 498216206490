define("lh4/routes/authenticated/epro/timeclock/shifts", ["exports", "lh4/enums/date-range-title", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/lighthouse-permissions-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/utils/date-moment", "moment"], function (_exports, _dateRangeTitle, _authenticatedRouteMixin, _lighthousePermissionsMixin, _mobileScrollRouteMixin, _dateMoment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _lighthousePermissionsMixin.default, _mobileScrollRouteMixin.default, {
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    requiredPermissions: 'manage echo-pro/time-clock',
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (!this.location.model.features.newTimeClockManagement) {
        this.replaceWith('authenticated.locations.location.echo-pro.manage-pos.manage-employees', this.location.model.id);
      }
    },
    model: function model(params) {
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        preferences: this.store.findRecord('preferences', this.tokenData.get('data.sub')),
        employees: this.store.query('echo-pro/employee', {
          location: location,
          order: 'name asc'
        }),
        settings: this.store.queryRecord('echo-pro/setting', {
          location: location,
          type: ['accounting', 'business']
        }),
        params: params
      });
    },
    afterModel: function afterModel(modelParam) {
      var model = modelParam;
      var eod = model.preferences.eod,
          employees = model.employees,
          params = model.params;
      var location = this.location.model.id;
      var isSameLocation = location === params.location;
      var selectedIdsRaw = params.selectedIds;

      if (typeof params.selectedIds === 'string') {
        try {
          selectedIdsRaw = JSON.parse(params.selectedIds);
        } catch (e) {
          // Swallow the error and set selectedIdsRaw to an empty array
          selectedIdsRaw = [];
        }
      }

      var selectedIds = selectedIdsRaw.filter(function (id) {
        return employees.findBy('guid', id);
      });
      var all = params.all || !selectedIds.length && params.selectedIds.length > 0;

      var _Period$TODAY = _dateMoment.Period.TODAY({
        eod: eod
      }),
          title = _Period$TODAY.title,
          startDate = _Period$TODAY.startDate,
          endDate = _Period$TODAY.endDate;

      model.adjustedProperties = _objectSpread(_objectSpread({}, params), {}, {
        location: location,
        all: all,
        selectedIds: selectedIds,
        maxDate: endDate,
        offset: isSameLocation ? params.offset : 0
      });

      if (!params.from || !params.to) {
        var from = params.from || startDate.format();
        var to = params.to || endDate.format();
        Object.assign(model.adjustedProperties, {
          rangeTitle: title,
          from: from,
          to: to,
          mobileFrom: (0, _moment.default)(from),
          mobileTo: (0, _moment.default)(to)
        });
      } else {
        Object.assign(model.adjustedProperties, {
          rangeTitle: _dateRangeTitle.DATE_RANGE_TITLE.CUSTOM,
          mobileFrom: (0, _moment.default)(params.from),
          mobileTo: (0, _moment.default)(params.to)
        });
      }

      return this.populateShifts(model.adjustedProperties).then(function (shifts) {
        model.adjustedProperties.shifts = shifts;
      });
    },
    setupController: function setupController() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var controller = args[0],
          model = args[1];

      this._super.apply(this, args);

      controller.setProperties(model.adjustedProperties);
      delete model.adjustedProperties;
      delete model.params;
    },
    populateShifts: function populateShifts(_ref) {
      var all = _ref.all,
          selectedIds = _ref.selectedIds,
          from = _ref.from,
          to = _ref.to,
          offset = _ref.offset,
          limit = _ref.limit,
          order = _ref.order,
          sort = _ref.sort;

      if (!all && !selectedIds.length) {
        return Ember.RSVP.resolve([]);
      }

      var filter = {
        locationId: {
          $eq: this.location.model.id
        },
        voided: {
          $eq: false
        },
        isTracked: {
          $eq: true
        },
        $or: [{
          $and: [{
            clockedInAt: {
              $gte: from
            }
          }, {
            clockedInAt: {
              $lte: to
            }
          }]
        }, {
          $and: [{
            clockedOutAt: {
              $gte: from
            }
          }, {
            clockedOutAt: {
              $lte: to
            }
          }]
        }]
      };

      if (!all) {
        filter.employeeGuid = {
          $in: selectedIds
        };
      }

      return this.store.query('echo-pro/time-clock-shift', {
        filter: JSON.stringify(filter),
        order: "".concat(order, " ").concat(sort),
        limit: limit,
        offset: offset
      });
    },
    actions: {
      populateShifts: function populateShifts(params) {
        var _this = this;

        this.populateShifts(params).then(function (shifts) {
          _this.controller.set('shifts', shifts);
        });
      }
    }
  });

  _exports.default = _default;
});