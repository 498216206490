define("lh4/mirage/fixtures/internet-payments/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ACH_TRANSACTION_FIXUTRE = _exports.DISPUTE_FIXTURE = _exports.BATCH_DETAILS_FIXTURE = _exports.FUNDS_IN_TRANSIT = _exports.TRANSACTION_RESPONSE_FIXTURE = void 0;

  /* eslint-disable import/prefer-default-export */
  var TRANSACTION_RESPONSE_FIXTURE = {
    trees: [{
      nodeType: 'transaction',
      parentGuid: null,
      level: 0,
      rows: [{
        cols: [{
          colSpan: 10,
          rowSpan: 1,
          value: 'Transactions Report',
          dataType: 'header'
        }],
        type: 'title'
      }],
      children: [{
        nodeType: 'header',
        parentGuid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Transaction ID',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Date',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Order Status',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Batch #',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Account Number',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Customer Name',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Customer Email',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Amount',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Type',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Risk',
            dataType: 'header'
          }],
          type: 'header'
        }],
        children: [],
        isExpandable: false,
        guid: '16f723e5-4450-596e-8415-bef094b4d10b'
      }, {
        nodeType: 'transaction',
        parentGuid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '0606083049',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Jun. 6, 2022',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'unsettled',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4321********1119',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'SDK TestName',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'sdkTest@kountsdktestdomain.com',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$12.34',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'SALE',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'approved',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '61cabcdd-ddcf-53ff-a6a4-cd6cdf6dff40'
      }, {
        nodeType: 'transaction',
        parentGuid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '0606102751',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Jun. 6, 2022',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'unsettled',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4321********1119',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'SDK TestName',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'sdkTest@kountsdktestdomain.com',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$12.34',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'SALE',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'approved',
            dataType: 'string'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '5dbb19a3-0411-5231-be62-f684b1db455f'
      }],
      isExpandable: true,
      guid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc'
    }],
    title: 'Transactions Report',
    start: '2022-06-06T00:00:00.000+00:00',
    end: '2022-06-06T23:59:59.999+00:00',
    pagination: {
      currentPage: 1,
      pageCount: 1
    }
  };
  _exports.TRANSACTION_RESPONSE_FIXTURE = TRANSACTION_RESPONSE_FIXTURE;
  var FUNDS_IN_TRANSIT = {
    trees: [{
      nodeType: 'funds-in-transit',
      parentGuid: null,
      level: 0,
      rows: [{
        cols: [{
          colSpan: 5,
          rowSpan: 1,
          value: 'Funds in Transit',
          dataType: 'header'
        }],
        type: 'title'
      }],
      children: [{
        nodeType: 'header',
        parentGuid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Reporting Date',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Merchant ID',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Merchant Name',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Batch Closed Date',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Batch Total',
            dataType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Funds in Transit',
            dataType: 'header'
          }],
          type: 'header'
        }],
        children: [],
        isExpandable: false,
        guid: '16f723e5-4450-596e-8415-bef094b4d10b'
      }, {
        nodeType: 'funds-in-transit',
        parentGuid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '12/31/2022',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0021776281',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Internet Business',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/30/2022',
            dataType: 'date'
          }, {
            colSpan: 2,
            rowSpan: 1,
            value: '$1,921,539.05',
            dataType: 'money'
          }, {
            colSpan: 2,
            rowSpan: 1,
            value: '$1,921,539.05',
            dataType: 'money'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '61cabcdd-ddcf-53ff-a6a4-cd6cdf6dff40'
      }, {
        nodeType: 'funds-in-transit',
        parentGuid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '12/31/2022',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0021776281',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Internet Business',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/30/2022',
            dataType: 'date'
          }, {
            colSpan: 2,
            rowSpan: 1,
            value: '$1,863,277.40',
            dataType: 'money'
          }, {
            colSpan: 2,
            rowSpan: 1,
            value: '$1,863,277.40',
            dataType: 'money'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '5dbb19a3-0411-5231-be62-f684b1db455g'
      }, {
        nodeType: 'funds-in-transit',
        parentGuid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc',
        level: 1,
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '12/31/2022',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0021776281',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Internet Business',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '12/31/2022',
            dataType: 'date'
          }, {
            colSpan: 2,
            rowSpan: 1,
            value: '$1,266,481.33',
            dataType: 'money'
          }, {
            colSpan: 2,
            rowSpan: 1,
            value: '$398,762.05',
            dataType: 'money'
          }],
          type: 'data'
        }],
        children: [],
        isExpandable: false,
        guid: '5dbb19a3-0411-5231-be62-f684b1db455f'
      }],
      isExpandable: true,
      guid: '5a7b1d2e-7722-5826-bb9a-14469c74b2fc'
    }],
    title: 'Funds in Transit',
    start: '2022-12-31T00:00:00.000+00:00',
    end: '2022-12-31T23:59:59.999+00:00',
    pagination: {
      currentPage: 1,
      pageCount: 1
    }
  };
  _exports.FUNDS_IN_TRANSIT = FUNDS_IN_TRANSIT;
  var BATCH_DETAILS_FIXTURE = {
    trees: [{
      isExpandable: true,
      level: 0,
      nodeType: 'header',
      parentGuid: null,
      children: [{
        isExpandable: false,
        level: 1,
        nodeType: 'batch-details',
        parentGuid: 'bc2f5cbf-a819-53ee-bafa-7a63032ab272',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '0021776281',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '525363******8533',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'TRAN-11168015-36815-69',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0007327645',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'MASTERCARD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4570',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '10/21/23 05:00:06 PM',
            dataType: 'date-time'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Sale',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'USD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'money',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$265.00',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 0,
            dataType: 'float'
          }],
          type: 'data'
        }],
        guid: '1f336163-945b-56ec-9f8d-59a3e82883a1'
      }],
      guid: 'bc2f5cbf-a819-53ee-bafa-7a63032ab272'
    }],
    title: 'Batch Transaction Detail Report',
    start: '2023-10-23T00:00:00.000+00:00',
    end: '2023-10-23T23:59:59.999+00:00',
    pagination: {
      currentPage: 1,
      pageCount: 2,
      totalCount: 200
    }
  };
  _exports.BATCH_DETAILS_FIXTURE = BATCH_DETAILS_FIXTURE;
  var DISPUTE_FIXTURE = {
    trees: [{
      isExpandable: true,
      level: 0,
      nodeType: 'header',
      parentGuid: null,
      children: [{
        isExpandable: false,
        level: 1,
        nodeType: 'dispute',
        parentGuid: '8486ee65-f606-5aa2-9a15-360bf3f4af83',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '2023304036423',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '10/31/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Allocation',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '722b61bb618e9a@bogus.com',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'CUS-2967261-1349498238-25',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'New Case',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'N',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$717.15',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$717.15',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'VISA',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '10.4  ',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Other Fraud - Card- Absent  Environment ',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '434256******0052',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '24000973288558603411883',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '10/13/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '0007047112',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '047349',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }],
          type: 'data'
        }],
        guid: 'd87f15a1-4117-551f-bae5-ea6b6e79c758'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'dispute',
        parentGuid: '8486ee65-f606-5aa2-9a15-360bf3f4af83',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '2023304036715',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '10/31/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'First Chargeback',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Worked Case Resolved to Merchant',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Y',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$133.75',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'money',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'MASTERCARD',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '4837  ',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'No Cardholder Authorization',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '546162******4099',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '85369433294589203991597',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '10/22/2023',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }],
          type: 'data'
        }],
        guid: '1081dc39-2185-5dab-b042-29f2574566d5'
      }],
      guid: '8486ee65-f606-5aa2-9a15-360bf3f4af83'
    }],
    title: 'Dispute Report',
    start: '2023-10-01T00:00:00.000+00:00',
    end: '2023-10-31T23:59:59.999+00:00',
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: 2
    }
  };
  _exports.DISPUTE_FIXTURE = DISPUTE_FIXTURE;
  var ACH_TRANSACTION_FIXUTRE = {
    trees: [{
      isExpandable: true,
      level: 0,
      nodeType: 'header',
      parentGuid: null,
      children: [{
        isExpandable: false,
        level: 1,
        nodeType: 'ach-transaction',
        parentGuid: 'a098a873-75cd-51c5-939c-0b4fe60ec931',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '{99E56A95-19AC-428A-BF70-C0EECB7113F5}',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$4,480.92',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '11/07/2024',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'RETURNED R13',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Sale',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Business Account',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'date',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'BSRIQX NVHEXJ BNRUDB',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Copy JSON',
            dataType: 'string',
            tooltip: '{"events":[{"TranStatus":"PROCESSING","EventTime": "2024-11-07 07:42:32.560"},{"TranStatus":"RECEIVED","EventTime": "2024-11-07 07:42:45.457"},{"TranStatus":"RETURNED","EventTime": "2024-11-07 07:43:17.670"}]}',
            action: 'copy',
            actionTarget: 'tooltip'
          }],
          type: 'data'
        }],
        guid: '63f356f6-cfc2-52ad-ab3b-a36bcf70e497'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'ach-transaction',
        parentGuid: 'a098a873-75cd-51c5-939c-0b4fe60ec931',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '{99E56A95-19AC-428A-BF70-C0EECB7113F5}',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$4,480.92',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '11/07/2024',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'RETURNED R13',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Sale',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Business Account',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'date',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'BSRIQX NVHEXJ BNRUDB',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Copy JSON',
            dataType: 'string',
            tooltip: '{"events":[{"TranStatus":"PROCESSING","EventTime": "2024-11-07 07:42:32.560"},{"TranStatus":"RECEIVED","EventTime": "2024-11-07 07:42:45.457"},{"TranStatus":"RETURNED","EventTime": "2024-11-07 07:43:17.670"}]}',
            action: 'copy',
            actionTarget: 'tooltip'
          }],
          type: 'data'
        }],
        guid: '63f356f6-cfc2-52ad-ab3b-a36bcf70e497'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'ach-transaction',
        parentGuid: 'a098a873-75cd-51c5-939c-0b4fe60ec931',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: '{99E56A95-19AC-428A-BF70-C0EECB7113F5}',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '$4,480.92',
            dataType: 'money'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '11/07/2024',
            dataType: 'date'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'RETURNED R13',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Sale',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Business Account',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'date',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'BSRIQX NVHEXJ BNRUDB',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: 'Copy JSON',
            dataType: 'string',
            tooltip: '{"events":[{"TranStatus":"PROCESSING","EventTime": "2024-11-07 07:42:32.560"},{"TranStatus":"RECEIVED","EventTime": "2024-11-07 07:42:45.457"},{"TranStatus":"RETURNED","EventTime": "2024-11-07 07:43:17.670"}]}',
            action: 'copy',
            actionTarget: 'tooltip'
          }],
          type: 'data'
        }],
        guid: '63f356f6-cfc2-52ad-ab3b-a36bcf70e497'
      }, {
        isExpandable: false,
        level: 1,
        nodeType: 'group',
        parentGuid: 'a098a873-75cd-51c5-939c-0b4fe60ec931',
        children: [],
        rows: [{
          cols: [{
            colSpan: 1,
            rowSpan: 1,
            value: 'Report Results:',
            dataType: 'string',
            columnType: 'header'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '3 Transactions',
            dataType: 'string'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }, {
            colSpan: 1,
            rowSpan: 1,
            value: '',
            dataType: 'string',
            columnType: 'empty'
          }],
          type: 'total'
        }],
        guid: '27f66159-af20-5746-892b-ad83acd466e7'
      }],
      rows: [{
        cols: [{
          colSpan: 1,
          rowSpan: 1,
          value: 'Transaction ID',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Amount',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'ACH Entry Date',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Status',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Transaction Type',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Account Type',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Est. Funding Date',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Customer Name',
          dataType: 'string',
          columnType: 'header'
        }, {
          colSpan: 1,
          rowSpan: 1,
          value: 'Transaction History',
          dataType: 'string',
          columnType: 'header'
        }],
        type: 'header'
      }],
      guid: 'a098a873-75cd-51c5-939c-0b4fe60ec931'
    }],
    title: 'ACH Transaction Report',
    start: '2024-11-07T00:00:00.000+00:00',
    end: '2024-11-07T23:59:59.999+00:00',
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: 3
    }
  };
  _exports.ACH_TRANSACTION_FIXUTRE = ACH_TRANSACTION_FIXUTRE;
});