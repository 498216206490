define("lh4/constants/launch-control/modules", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MODULE_STATUS = _exports.REQUIRED_MODULES = _exports.TITLE_TO_MODULE_MAPPING = _exports.KIOSK_MODULE = _exports.WEBSITE_MODULE = _exports.DISCOUNT_MODULE = _exports.DISCOUNT_STEP_NAMES = _exports.EMPLOYEES_MODULE = _exports.EMPLOYEES_STEP_NAMES = _exports.FLOOR_PLAN_MODULE = _exports.SKYTAB_BI_MODULE = _exports.ADVANTAGE_PROGRAM_MODULE = _exports.RESERVATIONS_MODULE = _exports.RESERVATIONS_STEP_NAME = _exports.LOYALTY_MODULE = _exports.LOYALTY_STEP_NAMES = _exports.GIFT_CARDS_MODULE = _exports.INTEGRATIONS_MODULE_V2 = _exports.ONLINE_ORDERING_MODULE = _exports.RESTAURANTS_BASICS_DESCRIPTION_ITEMS = _exports.RESTAURANTS_BASICS_MODULE = _exports.MENU_UPLOAD_MODULE = _exports.MENU_UPLOAD_STEP_NAMES = _exports.INSTALLATION_DETAILS_MODULE = void 0;
  var INSTALLATION_DETAILS_STEP_NAMES = ['contacts', 'shipping-address', 'setup-date'];
  var INSTALLATION_DETAILS_MODULE = Object.freeze({
    name: 'mod-installation-details-v1',
    stepNames: INSTALLATION_DETAILS_STEP_NAMES,
    route: {
      routeName: 'authenticated.launch-control.installation-details',
      stepName: INSTALLATION_DETAILS_STEP_NAMES[0]
    }
  });
  _exports.INSTALLATION_DETAILS_MODULE = INSTALLATION_DETAILS_MODULE;
  var MENU_UPLOAD_STEP_NAMES = Object.freeze({
    UPLOAD: 'upload',
    TAXES: 'taxes'
  });
  _exports.MENU_UPLOAD_STEP_NAMES = MENU_UPLOAD_STEP_NAMES;
  var MENU_UPLOAD_MODULE = Object.freeze({
    name: 'mod-menu-v1',
    stepNames: [MENU_UPLOAD_STEP_NAMES.UPLOAD, MENU_UPLOAD_STEP_NAMES.TAXES],
    route: {
      routeName: 'authenticated.launch-control.menu',
      stepName: MENU_UPLOAD_STEP_NAMES.UPLOAD
    }
  });
  _exports.MENU_UPLOAD_MODULE = MENU_UPLOAD_MODULE;
  var RESTAURANTS_BASICS_STEP_NAMES = ['location-details', 'logo-upload'];
  var RESTAURANTS_BASICS_MODULE = Object.freeze({
    name: 'mod-restaurant-basics-v1',
    stepNames: RESTAURANTS_BASICS_STEP_NAMES,
    route: {
      routeName: 'authenticated.launch-control.restaurant-basics',
      stepName: RESTAURANTS_BASICS_STEP_NAMES[0]
    }
  });
  _exports.RESTAURANTS_BASICS_MODULE = RESTAURANTS_BASICS_MODULE;
  var RESTAURANTS_BASICS_DESCRIPTION_ITEMS = ['Counter Service only', 'Includes Table Service'];
  _exports.RESTAURANTS_BASICS_DESCRIPTION_ITEMS = RESTAURANTS_BASICS_DESCRIPTION_ITEMS;
  var ONLINE_ORDERING_STEP_NAMES = ['lets-start', 'order-types', 'order-type-settings', 'delivery-range', 'delivery-area', 'delivery-settings', 'logo-upload'];
  var ONLINE_ORDERING_MODULE = Object.freeze({
    name: 'mod-online-ordering-v1',
    stepNames: ONLINE_ORDERING_STEP_NAMES,
    route: {
      routeName: 'authenticated.launch-control.online-ordering',
      stepName: ONLINE_ORDERING_STEP_NAMES[0]
    }
  });
  _exports.ONLINE_ORDERING_MODULE = ONLINE_ORDERING_MODULE;
  var INTEGRATIONS_MODULE_V2 = Object.freeze({
    name: 'mod-integrations-v2',
    stepNames: ['create-schedule', 'menu-items', 'delivery-platforms', 'info'],
    route: {
      routeName: 'authenticated.launch-control.integrations-v2'
    }
  });
  _exports.INTEGRATIONS_MODULE_V2 = INTEGRATIONS_MODULE_V2;
  var GIFT_CARDS_MODULE = Object.freeze({
    name: 'mod-gift-cards-v1',
    stepNames: ['iframe'],
    route: {
      routeName: 'authenticated.launch-control.gift-cards'
    }
  });
  _exports.GIFT_CARDS_MODULE = GIFT_CARDS_MODULE;
  var LOYALTY_STEP_NAMES = Object.freeze({
    PROGRAMS: 'programs',
    DISCOUNTS: 'discounts',
    PROMOTIONS: 'promotions'
  });
  _exports.LOYALTY_STEP_NAMES = LOYALTY_STEP_NAMES;
  var LOYALTY_MODULE = Object.freeze({
    name: 'mod-loyalty-v1',
    stepNames: [LOYALTY_STEP_NAMES.PROGRAMS, LOYALTY_STEP_NAMES.DISCOUNTS, LOYALTY_STEP_NAMES.PROMOTIONS],
    route: {
      routeName: 'authenticated.launch-control.loyalty'
    }
  });
  _exports.LOYALTY_MODULE = LOYALTY_MODULE;
  var RESERVATIONS_STEP_NAME = Object.freeze({
    BLOCKS: 'blocks',
    SETTINGS: 'settings'
  });
  _exports.RESERVATIONS_STEP_NAME = RESERVATIONS_STEP_NAME;
  var RESERVATIONS_MODULE = Object.freeze({
    name: 'mod-reservations-v1',
    stepNames: [RESERVATIONS_STEP_NAME.BLOCKS, RESERVATIONS_STEP_NAME.SETTINGS],
    route: {
      routeName: 'authenticated.launch-control.reservations'
    }
  });
  _exports.RESERVATIONS_MODULE = RESERVATIONS_MODULE;
  var ADVANTAGE_PROGRAM_MODULE = Object.freeze({
    name: 'mod-advantage-program-v1',
    stepNames: []
  });
  _exports.ADVANTAGE_PROGRAM_MODULE = ADVANTAGE_PROGRAM_MODULE;
  var SKYTAB_BI_MODULE = Object.freeze({
    name: 'mod-skytab-bi-v1',
    stepNames: ['submit'],
    route: {
      routeName: 'authenticated.launch-control.skytab-bi'
    }
  });
  _exports.SKYTAB_BI_MODULE = SKYTAB_BI_MODULE;
  var FLOOR_PLAN_MODULE = Object.freeze({
    name: 'mod-table-layout-v1',
    stepNames: ['table-layout'],
    route: {
      routeName: 'authenticated.launch-control.table-layout'
    }
  });
  _exports.FLOOR_PLAN_MODULE = FLOOR_PLAN_MODULE;
  var EMPLOYEES_STEP_NAMES = Object.freeze({
    JOBS: 'jobs',
    EMPLOYEES: 'employees'
  });
  _exports.EMPLOYEES_STEP_NAMES = EMPLOYEES_STEP_NAMES;
  var EMPLOYEES_MODULE_STEPNAMES = [EMPLOYEES_STEP_NAMES.JOBS, EMPLOYEES_STEP_NAMES.EMPLOYEES];
  var EMPLOYEES_MODULE = Object.freeze({
    name: 'mod-employees-v1',
    stepNames: EMPLOYEES_MODULE_STEPNAMES,
    route: {
      routeName: 'authenticated.launch-control.employees' // stepName: EMPLOYEES_MODULE_STEPNAMES[0],

    }
  });
  _exports.EMPLOYEES_MODULE = EMPLOYEES_MODULE;
  var DISCOUNT_STEP_NAMES = Object.freeze({
    DISCOUNTS: 'discounts',
    GRATUITIES: 'gratuities'
  });
  _exports.DISCOUNT_STEP_NAMES = DISCOUNT_STEP_NAMES;
  var DISCOUNT_MODULE_STEPNAMES = [DISCOUNT_STEP_NAMES.DISCOUNTS, DISCOUNT_STEP_NAMES.GRATUITIES];
  var DISCOUNT_MODULE = Object.freeze({
    name: 'mod-discounts-v1',
    stepNames: DISCOUNT_MODULE_STEPNAMES,
    route: {
      routeName: 'authenticated.launch-control.discounts' // stepName: DISCOUNT_MODULE_STEPNAMES[0],

    }
  });
  _exports.DISCOUNT_MODULE = DISCOUNT_MODULE;
  var WEBSITE_STEP_NAMES = ['submit'];
  var WEBSITE_MODULE = Object.freeze({
    name: 'mod-website-v1',
    stepNames: WEBSITE_STEP_NAMES,
    route: {
      routeName: 'authenticated.launch-control.website' // stepName: WEBSITE_STEP_NAMES[0],

    }
  });
  _exports.WEBSITE_MODULE = WEBSITE_MODULE;
  var KIOSK_MODULE_STEPNAMES = ['kiosk-order-more', 'kiosk-order-details', 'kiosk-order-checkout', 'kiosk-order-thanks', 'order-types', 'order-settings', 'tip-settings', 'branding'];
  var KIOSK_MODULE = Object.freeze({
    name: 'mod-kiosk-v1',
    stepNames: KIOSK_MODULE_STEPNAMES,
    route: {
      routeName: 'authenticated.launch-control.kiosk' // stepName: KIOSK_MODULE_STEPNAMES[0],

    }
  });
  _exports.KIOSK_MODULE = KIOSK_MODULE;
  var TITLE_TO_MODULE_MAPPING = {
    'Installation Details': INSTALLATION_DETAILS_MODULE.name,
    'Upload your menu': MENU_UPLOAD_MODULE.name,
    'Restaurant Basics': RESTAURANTS_BASICS_MODULE.name,
    'Online Ordering': ONLINE_ORDERING_MODULE.name,
    'Gift Cards': GIFT_CARDS_MODULE.name,
    Loyalty: LOYALTY_MODULE.name,
    Reservations: RESERVATIONS_MODULE.name,
    'Advantage program': ADVANTAGE_PROGRAM_MODULE.name,
    'Business Intelligence (BI)': SKYTAB_BI_MODULE.name,
    Website: WEBSITE_MODULE.name,
    'Floor Plan': FLOOR_PLAN_MODULE.name,
    Employees: EMPLOYEES_MODULE.name,
    'Finish Online Ordering': INTEGRATIONS_MODULE_V2.name,
    'Set Up Kiosk': KIOSK_MODULE.name
  }; // being used in navigation

  _exports.TITLE_TO_MODULE_MAPPING = TITLE_TO_MODULE_MAPPING;
  var REQUIRED_MODULES = [INSTALLATION_DETAILS_MODULE.name, MENU_UPLOAD_MODULE.name, RESTAURANTS_BASICS_MODULE.name];
  _exports.REQUIRED_MODULES = REQUIRED_MODULES;
  var MODULE_STATUS = {
    PENDING: 'PENDING',
    WAITING_ON_MENU: 'WAITING_ON_MENU',
    SKIPPED: 'SKIPPED',
    COMPLETED: 'COMPLETED',
    AVAILABLE: 'AVAILABLE',
    IN_PROGRESS: 'IN_PROGRESS',
    HIDDEN: 'HIDDEN'
  };
  _exports.MODULE_STATUS = MODULE_STATUS;
});