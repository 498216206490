define("lh4/abilities/echo-pro/invoice", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    session: Ember.inject.service(),
    location: Ember.inject.service(),
    permissions: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    canViewInvoices: (0, _abilityMixin.computedAbility)(function canViewInvoices() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.INVOICES] && this.hasLocationPermission(_permissions.default.INVOICES.VIEW_INVOICES);
    })
  });

  _exports.default = _default;
});