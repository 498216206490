define("lh4/services/widget-loader", ["exports", "lh4/utils/hash-to-query-string"], function (_exports, _hashToQueryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    _cache: {},
    _location: undefined,
    load: function load(widget, location, timezone) {
      if (this._location !== location.id) {
        this.set('_cache', {});
        this.set('_location', location.id);
      }

      var key = JSON.stringify(widget);

      if (!this._cache[key]) {
        this._cache[key] = this.ajax.request("/api/v2/dashboard/widgets/".concat(widget.slug), {
          data: {
            locationId: location.id,
            product: location.product,
            timezone: location.timezone || timezone,
            options: widget.settings
          }
        });
      }

      return this._cache[key];
    },
    loadForLocations: function loadForLocations(widget, locationIds, timezone, product) {
      var uniqueLocationIds = Array.from(new Set(locationIds));
      var key = JSON.stringify({
        widget: widget,
        locationIds: uniqueLocationIds,
        timezone: timezone,
        product: product
      });
      var queryString = (0, _hashToQueryString.default)({
        product: product,
        timezone: timezone,
        options: widget.settings
      });

      if (!this._cache[key]) {
        this._cache[key] = this.ajax.request("/api/v3/dashboard/widgets/".concat(widget.slug, "?").concat(queryString), {
          method: 'POST',
          data: {
            locationIds: uniqueLocationIds
          }
        });
      }

      return this._cache[key];
    }
  });

  _exports.default = _default;
});