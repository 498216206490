define("lh4/components/epro/accounting/tax-form", ["exports", "lh4/utils/validate-type", "lh4/models/echo-pro/tax-rule", "ember-uuid"], function (_exports, _validateType, _taxRule, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend({
    corporation: Ember.inject.service(),
    classNames: ['tax-form'],
    locale: Ember.inject.service(),
    intl: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.taxTypeOptions = [{
        value: true,
        name: this.intl.t('components:epro:accounting:tax-form:tax-type-options:flat', {
          currencySymbol: this.locale.currencySymbol
        })
      }, {
        value: false,
        name: this.intl.t('components:epro:accounting:tax-form:tax-type-options:percentage')
      }];
      (0, _validateType.default)(this.changeset.taxRuleChangesets, _taxRule.ruleTypes, 'default', 'ruleType');
    },
    itemsList: Ember.computed('items', 'changeset.itemTaxMapChangesets.[]', function getter() {
      var _this = this;

      return this.items.toArray().map(function (item) {
        var changeset = _this.changeset.itemTaxMapChangesets.toArray().find(function (map) {
          return map.get('itemGuid') === item.get('guid');
        });

        return _objectSpread(_objectSpread({}, item.toJSON({
          includeId: true
        })), {}, {
          changeset: changeset
        });
      });
    }),
    taxSetsList: Ember.computed('taxSets', 'changeset.taxSetTaxMapChangesets.[]', function getter() {
      var _this2 = this;

      return this.taxSets.toArray().map(function (taxSet) {
        var changeset = _this2.changeset.taxSetTaxMapChangesets.toArray().find(function (map) {
          return map.get('taxSetGuid') === taxSet.get('guid');
        });

        return _objectSpread(_objectSpread({}, taxSet.toJSON({
          includeId: true
        })), {}, {
          changeset: changeset
        });
      });
    }),
    validateRuleType: function validateRuleType() {
      (0, _validateType.default)(this.changeset.taxRuleChangesets, _taxRule.ruleTypes, 'default', 'ruleType');
    },
    hasNoLiquorItem: Ember.computed('items', 'changeset.itemIds.[]', 'taxSets', function getter() {
      var _this3 = this;

      var liquorItem = this.changeset.get('itemIds').find(function (changesetItemId) {
        var changesetItem = _this3.items.findBy('guid', changesetItemId);

        var taxSet = _this3.taxSets.findBy('guid', changesetItem.taxSetGuid);

        if (taxSet) {
          return taxSet.isAllLiquor;
        }

        return false;
      });
      return !liquorItem;
    }),
    hasEnoughRules: Ember.computed('changeset.taxRuleChangesets.@each.ruleType', function getter() {
      return this.changeset.taxRuleChangesets.toArray().length >= _taxRule.ruleTypes.length;
    }),
    hasPriceRule: Ember.computed('changeset.taxRuleChangesets.@each.ruleType', function getter() {
      return this.changeset.taxRuleChangesets.toArray().some(function (rule) {
        return rule.get('ruleType') === _taxRule.TAX_RULE_TYPES.PRICE;
      });
    }),
    hasQuantityRule: Ember.computed('changeset.taxRuleChangesets.@each.ruleType', function getter() {
      return this.changeset.taxRuleChangesets.toArray().some(function (rule) {
        return rule.get('ruleType') === _taxRule.TAX_RULE_TYPES.QUANTITY;
      });
    }),
    headers: Ember.computed('hasPriceRule', 'hasQuantityRule', function getter() {
      return ['Name'].concat(_toConsumableArray(this.hasPriceRule ? ['Price'] : []), _toConsumableArray(this.hasQuantityRule ? ['Quantity'] : []));
    }),
    isPriceRuleEnabled: Ember.computed('changeset.taxRuleChangesets.@each.{ruleType,isActive}', function getter() {
      return this.changeset.taxRuleChangesets.toArray().some(function (rule) {
        return rule.get('ruleType') === _taxRule.TAX_RULE_TYPES.PRICE && rule.get('isActive');
      });
    }),
    isQuantityRuleEnabled: Ember.computed('changeset.taxRuleChangesets.@each.{ruleType,isActive}', function getter() {
      return this.changeset.taxRuleChangesets.toArray().some(function (rule) {
        return rule.get('ruleType') === _taxRule.TAX_RULE_TYPES.QUANTITY && rule.get('isActive');
      });
    }),
    filteredRuleTypes: Ember.computed('changeset.taxRuleChangesets.@each.ruleType', function getter() {
      var _this4 = this;

      return _taxRule.ruleTypes.filter(function (o) {
        return !_this4.changeset.taxRuleChangesets.toArray().some(function (rule) {
          return rule.get('ruleType') === o.name;
        });
      });
    }),
    actions: {
      addItem: function addItem(selected) {
        var _this5 = this;

        var itemsToAdd = selected.filter(function (guid) {
          return !_this5.changeset.itemTaxMapChangesets.findBy('itemGuid', guid);
        }).map(function (guid) {
          var id = (0, _emberUuid.v4)();
          return _this5.buildChildChangeset(_this5.changeset.data.addItemTaxMap({
            id: id,
            guid: id,
            taxGuid: _this5.changeset.get('guid'),
            itemGuid: guid
          }));
        });
        this.changeset.itemTaxMapChangesets.pushObjects(itemsToAdd);
        var itemsToRemove = this.changeset.itemTaxMapChangesets.filter(function (item) {
          return !selected.includes(item.get('itemGuid'));
        });
        this.changeset.itemTaxMapChangesets.removeObjects(itemsToRemove);
        this.changeset.set('itemIds', selected);
      },
      removeItem: function removeItem(guids) {
        var itemsToRemove = this.changeset.itemTaxMapChangesets.filter(function (item) {
          return !guids.includes(item.get('itemGuid'));
        });
        this.changeset.itemTaxMapChangesets.removeObjects(itemsToRemove);
        this.changeset.set('itemIds', this.changeset.get('itemIds').filter(function (id) {
          return guids.includes(id);
        }));

        if (this.hasNoLiquorItem) {
          this.set('changeset.isNotAppliedIfAllLiquor', false);
          this.set('changeset.isHiddenIfAllLiquor', false);
        }
      },
      addRevenueClass: function addRevenueClass(selected) {
        var _this6 = this;

        var taxSetsToAdd = selected.filter(function (guid) {
          return !_this6.changeset.taxSetTaxMapChangesets.findBy('taxSetGuid', guid);
        }).map(function (guid) {
          var id = (0, _emberUuid.v4)();
          return _this6.buildChildChangeset(_this6.changeset.data.addTaxSetTaxMap({
            id: id,
            guid: id,
            taxGuid: _this6.changeset.get('guid'),
            taxSetGuid: guid
          }));
        });
        this.changeset.taxSetTaxMapChangesets.pushObjects(taxSetsToAdd);
        var taxSetsToRemove = this.changeset.taxSetTaxMapChangesets.filter(function (taxSet) {
          return !selected.includes(taxSet.get('taxSetGuid'));
        });
        this.changeset.taxSetTaxMapChangesets.removeObjects(taxSetsToRemove);
        this.changeset.set('taxSetIds', selected);
      },
      removeRevenueClass: function removeRevenueClass(guids) {
        var taxSetsToRemove = this.changeset.taxSetTaxMapChangesets.filter(function (taxSet) {
          return !guids.includes(taxSet.get('taxSetGuid'));
        });
        this.changeset.taxSetTaxMapChangesets.removeObjects(taxSetsToRemove);
        this.changeset.set('taxSetIds', this.changeset.get('taxSetIds').filter(function (id) {
          return guids.includes(id);
        }));
      },
      changeProperty: function changeProperty(record, value, property) {
        record.set(property, value);
        this.validateRuleType();
      },
      addTaxRule: function addTaxRule() {
        var ruleType = this.filteredRuleTypes.get('lastObject').name;
        var newTaxRuleRecord = this.changeset.data.addTaxRule({
          id: (0, _emberUuid.v4)(),
          ruleType: ruleType
        });
        var changeset = this.buildChildChangeset(newTaxRuleRecord);
        this.changeset.taxRuleChangesets.pushObject(changeset);
      },
      deleteTaxRule: function deleteTaxRule(record) {
        this.changeset.taxRuleChangesets.removeObject(record);
        this.validateRuleType();
      }
    }
  });

  _exports.default = _default;
});