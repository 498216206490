define("lh4/routes/authenticated/settings/users", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    tokenData: Ember.inject.service(),
    ajax: Ember.inject.service(),
    media: Ember.inject.service(),
    permissions: Ember.inject.service(),
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    queryParams: {
      searchTerm: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      offset: {
        refreshModel: true
      },
      order: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortDir: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      statuses: {
        refreshModel: true
      },
      locations: {
        refreshModel: true
      },
      allStatuses: {
        refreshModel: true
      },
      allLocations: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (this.can.cannot('manage users in general')) {
        this.transitionTo('authenticated.settings.index');
      }
    },
    model: function model(_ref) {
      var offset = _ref.offset,
          searchTerm = _ref.searchTerm,
          order = _ref.order,
          limit = _ref.limit,
          sort = _ref.sort,
          statuses = _ref.statuses,
          locations = _ref.locations;
      return Ember.RSVP.hash({
        users: this.store.query('settings/user', {
          offset: offset,
          limit: limit,
          searchTerm: searchTerm,
          sortBy: order,
          sortDir: sort,
          statuses: statuses,
          locations: locations
        }),
        locations: this.modelFor('authenticated').locations,
        skytabEnterpriseEnabled: this.launchDarkly.featureFlags['skytab-enterprise-enabled']
      });
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});