define("lh4/components/reporting", ["exports", "ember-cp-validations", "lh4/enums/date-range-title", "lh4/enums/date-time-format", "lh4/utils/combine-dates", "moment"], function (_exports, _emberCpValidations, _dateRangeTitle, _dateTimeFormat, _combineDates, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.INPUT_DATE_TIME_FORMAT = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var INPUT_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm A';
  _exports.INPUT_DATE_TIME_FORMAT = INPUT_DATE_TIME_FORMAT;
  var Validations = (0, _emberCpValidations.buildValidations)({
    reportTypeSelected: (0, _emberCpValidations.validator)('presence', true),
    'dates.startsAt': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.or('model.dateDisabled', 'model.startDateDisabled')
    }),
    'dates.startsAtTime': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.or('model.dateDisabled', 'model.startTimeDisabled')
    }),
    'dates.endsAt': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.or('model.dateDisabled', 'model.endDateDisabled')
    }),
    'dates.endsAtTime': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.or('model.dateDisabled', 'model.endTimeDisabled')
    })
  });
  /**
   * Get report date params hash
   *
   * @param {Object} params - report params
   * @returns string - report date params hash
   */

  var getReportDateParamsHash = function getReportDateParamsHash() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _params$start = params.start,
        start = _params$start === void 0 ? {
      date: false,
      time: false
    } : _params$start,
        _params$end = params.end,
        end = _params$end === void 0 ? {
      date: false,
      time: false
    } : _params$end;
    return "".concat(start.date).concat(start.time).concat(end.date).concat(end.time);
  };
  /**
   * Get report date picker options hash
   *
   * @param {Object} params - report params
   * @returns string - report date picker options hash
   */


  var getReportDatePickerOptionsHash = function getReportDatePickerOptionsHash() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _params$datePickerOpt = params.datePickerOptions,
        datePickerOptions = _params$datePickerOpt === void 0 ? {
      hasMinutesDisabled: false,
      maxDurationIso8601: null,
      // @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
      customDayPickRange: null
    } : _params$datePickerOpt;
    return JSON.stringify(datePickerOptions);
  };
  /**
   * Get date picker max duration in milliseconds
   *
   * @param {Object} instance - date picker instance
   * @returns {number|null} - max duration in milliseconds or null if not set on report date picker options
   */


  var getMaxDurationInMilliseconds = function getMaxDurationInMilliseconds(instance) {
    var _ref = instance.config.customOptions || {},
        maxDurationIso8601 = _ref.maxDurationIso8601;

    if (!maxDurationIso8601) {
      return null;
    }

    var duration = _moment.default.duration(maxDurationIso8601);

    return duration.isValid() ? duration.asMilliseconds() : null;
  };

  var _default = Ember.Component.extend(Validations, {
    didInsertElement: function didInsertElement() {
      var _this = this;

      // so the user doesn't change the dates in the url when they have a non-custom period selected
      if (this.reportPeriodSelected) {
        this.selectReportPeriod(this.reportPeriodSelected);
      } // if we pass in a period but not the dates we need to wait
      // for the dates to be selected before the report can be generated


      Ember.run.next(function () {
        if (_this.generate) {
          _this.send('generateReport');
        }
      });
    },
    router: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    maxDate: Ember.computed(function () {
      return (0, _moment.default)().endOf('day');
    }),
    defaultStartTime: Ember.computed(function () {
      return (0, _moment.default)().startOf('day');
    }),
    minuteSteps: 5,
    rangeTitle: null,
    verticalRangeWidth: 160,
    media: Ember.inject.service(),
    isPreGeneratedReportSelected: false,
    reportTypeSelected: null,
    reportTypeOptions: null,
    selectedReport: null,
    reportPeriodSelected: null,
    displayErrors: false,
    startTimeDisabled: Ember.computed.not('selectedReport.params.start.time'),
    endDateDisabled: Ember.computed.not('selectedReport.params.end.date'),
    endTimeDisabled: Ember.computed.not('selectedReport.params.end.time'),
    isDateTimeBusinessDay: Ember.computed('reportTypeSelected', function getter() {
      var _this2 = this,
          _currentReportOptions,
          _currentReportOptions2,
          _currentReportOptions3;

      var currentReportOptions = this.reportTypeOptions.find(function (_ref2) {
        var slug = _ref2.slug;
        return slug === _this2.reportTypeSelected;
      });
      return (_currentReportOptions = currentReportOptions === null || currentReportOptions === void 0 ? void 0 : (_currentReportOptions2 = currentReportOptions.params) === null || _currentReportOptions2 === void 0 ? void 0 : (_currentReportOptions3 = _currentReportOptions2.datePickerOptions) === null || _currentReportOptions3 === void 0 ? void 0 : _currentReportOptions3.isDateTimeBusinessDay) !== null && _currentReportOptions !== void 0 ? _currentReportOptions : true;
    }),
    isDayPartFilterAvailable: Ember.computed('reportTypeSelected', 'selectedLocations.[]', function getter() {
      var _this3 = this;

      var currentReportOptions = this.reportTypeOptions ? this.reportTypeOptions.find(function (_ref3) {
        var slug = _ref3.slug;
        return slug === _this3.reportTypeSelected;
      }) : null;
      return currentReportOptions ? currentReportOptions.isDayPartCompatible && this.selectedLocations.length === 1 : false;
    }),
    from: Ember.computed('dates.startsAt', 'dates.startsAtTime', {
      get: function get() {
        return this.dates.startsAt ? (0, _combineDates.default)(this.dates.startsAt, this.dates.startsAtTime, this.isDateTimeBusinessDay).format(_dateTimeFormat.default.LOCALIZED_SHORT) : null;
      },
      set: function set(key, value) {
        var firstReportDate = this.isDateTimeBusinessDay ? value : (0, _moment.default)(value).utc(true).startOf('day');
        this.set('dates.startsAt', firstReportDate.format());
        this.set('dates.startsAtTime', firstReportDate.format('hh:mmA'));
        return value;
      }
    }),
    to: Ember.computed('dates.endsAt', 'dates.endsAtTime', {
      get: function get() {
        return this.dates.endsAt ? (0, _combineDates.default)(this.dates.endsAt, this.dates.endsAtTime, this.isDateTimeBusinessDay).format(_dateTimeFormat.default.LOCALIZED_SHORT) : null;
      },
      set: function set(key, value) {
        var secondReportDate = this.isDateTimeBusinessDay ? value : (0, _moment.default)(value).utc(true).endOf('day');
        this.set('dates.endsAt', secondReportDate.format());
        this.set('dates.endsAtTime', secondReportDate.format('hh:mmA'));
        return value;
      }
    }),
    singleDate: Ember.computed('to', 'from', 'reportTypeSelected', {
      get: function get() {
        if (!this.reportTypeSelected) {
          return null;
        }

        return this.startDateDisabled ? this.to : this.from;
      },
      set: function set(value) {
        if (this.startDateDisabled) {
          this.set('to', value);
        } else {
          this.set('from', value);
        }

        return value;
      }
    }),
    getSameDateRangeAdjusted: function getSameDateRangeAdjusted(startDate, endDate) {
      return {
        start: startDate.startOf('day'),
        end: endDate.endOf('day')
      };
    },
    actions: {
      onAfterSelect: function onAfterSelect(instance, startDate, endDate) {
        var isSameDay = startDate.isSame(endDate, 'date');
        instance.setMaxDate((0, _moment.default)().endOf('day'));
        instance.setMinDate(null);

        if (!isSameDay) {
          return this.selectReportPeriod(this.reportTypeSelected, startDate, endDate, this.rangeTitle);
        }

        var _this$getSameDateRang = this.getSameDateRangeAdjusted(startDate, endDate),
            start = _this$getSameDateRang.start,
            end = _this$getSameDateRang.end;

        instance.setStart(start);
        instance.setEnd(end);
        return this.selectReportPeriod(this.reportTypeSelected, start, end, this.rangeTitle);
      },
      onDesktopBlur: function onDesktopBlur(event) {
        var inputMoment = (0, _moment.default)(event.target.value, INPUT_DATE_TIME_FORMAT);

        if (inputMoment.isValid()) {
          this.selectReportPeriod(this.reportTypeSelected, inputMoment);
        }
      },
      onMobileRangeChange: function onMobileRangeChange(rangeTitle, startDate, endDate) {
        this.set('rangeTitle', rangeTitle);
        return (0, _dateRangeTitle.isCustom)(rangeTitle) && !startDate ? this.selectReportPeriod() : this.selectReportPeriod(this.reportTypeSelected, startDate, endDate, this.rangeTitle);
      },
      onMobileChange: function onMobileChange(startDate, endDate) {
        this.selectReportPeriod(this.reportTypeSelected, startDate, endDate);
      },
      onRangeDesktopBlur: function onRangeDesktopBlur(event) {
        var _event$target$value$s = event.target.value.split(' - '),
            _event$target$value$s2 = _slicedToArray(_event$target$value$s, 2),
            from = _event$target$value$s2[0],
            to = _event$target$value$s2[1];

        var fromMoment = (0, _moment.default)(from, INPUT_DATE_TIME_FORMAT);
        var toMoment = (0, _moment.default)(to, INPUT_DATE_TIME_FORMAT);

        if (fromMoment.isValid() && toMoment.isValid()) {
          if (fromMoment > toMoment) {
            var _ref4 = [toMoment, fromMoment];
            fromMoment = _ref4[0];
            toMoment = _ref4[1];
          }

          this.selectReportPeriod(this.reportTypeSelected, fromMoment, toMoment, this.rangeTitle);
        } else {
          this.selectReportPeriod();
        }
      },
      onRangeSelect: function onRangeSelect(instance, range) {
        var startDate = range.startDate,
            endDate = range.endDate,
            title = range.title;

        if (instance.config.maxDate.isBefore((0, _moment.default)(startDate))) {
          instance.setMaxDate((0, _moment.default)().endOf('day'));
          instance.setMinDate(null);
        }

        instance.setStart(startDate);
        instance.setEnd(endDate);
        this.selectReportPeriod(this.reportTypeSelected, startDate, endDate, title);
      },
      onFirstSelect: function onFirstSelect(instance, startDate) {
        var maxDuration = getMaxDurationInMilliseconds(instance);

        var _ref5 = instance.config.customOptions || {},
            isMaxDurationLimitingStart = _ref5.isMaxDurationLimitingStart;

        if (!maxDuration) {
          return;
        }

        var isStartOnLastDay = startDate.isSame(instance.config.maxDate, 'day');
        /* eslint-disable no-param-reassign */

        if (isStartOnLastDay) {
          instance.config.minDate = startDate.clone().subtract(maxDuration);
        } else if (!isMaxDurationLimitingStart) {
          instance.config.minDate = startDate.clone().subtract(maxDuration);
          instance.config.maxDate = startDate.clone().add(maxDuration);
        } else {
          instance.config.minDate = startDate.clone();
          instance.config.maxDate = startDate.clone().add(maxDuration);
        }
        /* eslint-enable no-param-reassign */

      },
      onRangeTimeChange: function onRangeTimeChange(instance, startDate, endDate) {
        var from = this.from,
            maxDate = this.maxDate,
            rangeTitle = this.rangeTitle,
            reportTypeSelected = this.reportTypeSelected;
        var maxDuration = getMaxDurationInMilliseconds(instance);

        if (!maxDuration) {
          return this.selectReportPeriod(reportTypeSelected, startDate, endDate, rangeTitle);
        }

        var starLimitedToDuration = startDate.clone();
        var endLimitedToDuration = endDate.clone();
        var isStartChanged = !from || !startDate.isSame(from);

        if (isStartChanged) {
          // we subtract 1 millisecond because end dates are inclusive
          var maxEnd = startDate.clone().add(maxDuration - 1);

          if (maxDate && maxEnd.isAfter(maxDate)) {
            maxEnd = maxDate;
          }

          if (isStartChanged && endDate.isAfter(maxEnd)) {
            endLimitedToDuration = maxEnd;
            instance.setEnd(endLimitedToDuration);
          }
        } else {
          // we subtract 1 millisecond because end dates are inclusive
          var minStart = endDate.clone().subtract(maxDuration - 1);

          if (minStart.isAfter(startDate)) {
            starLimitedToDuration = minStart;
            instance.setStart(starLimitedToDuration);
          }
        }

        return this.selectReportPeriod(reportTypeSelected, starLimitedToDuration, endLimitedToDuration, rangeTitle);
      },
      onTimeChange: function onTimeChange(instance, date) {
        this.selectReportPeriod(this.reportTypeSelected, date);
      },
      onDateChange: function onDateChange(instance, start, end) {
        this.selectReportPeriod(this.reportTypeSelected, start, end);
      },
      onReportSelect: function onReportSelect(selectedReportType, _, event) {
        if (this.location.canViewLBM2 && this.onPreGeneratedReports) {
          var isPreGeneratedReportSelected = event[0].id === 'pre-generated-reports'; // reset report when switching from pre-generated to common report

          if (!isPreGeneratedReportSelected && this.isPreGeneratedReportSelected) {
            this.resetReport();
          }

          this.set('isPreGeneratedReportSelected', isPreGeneratedReportSelected); // For pre generated reports in internet payments

          if (isPreGeneratedReportSelected) {
            return this.onPreGeneratedReports(true, selectedReportType, event);
          }

          this.onPreGeneratedReports(false);
        }

        var reportOption = this.reportTypeOptions.find(function (_ref6) {
          var slug = _ref6.slug;
          return slug === selectedReportType;
        });
        this.setProperties({
          reportTypeSelected: selectedReportType,
          limit: 50,
          rangeTitle: undefined,
          reportType: selectedReportType
        });
        var from = this.from,
            to = this.to,
            params = this.selectedReport.params; // picker type (date/time params) changed, clear dates except for internet payments reports

        if (!this.location.canViewLBM2 && (getReportDateParamsHash(params) !== getReportDateParamsHash(reportOption.params) || getReportDatePickerOptionsHash(params) !== getReportDatePickerOptionsHash(reportOption.params))) {
          return this.selectReportPeriod();
        }

        var _reportOption$params = reportOption.params,
            end = _reportOption$params.end,
            start = _reportOption$params.start;
        var startDate = start && from ? (0, _moment.default)(from, INPUT_DATE_TIME_FORMAT) : undefined;
        var endDate = end && to ? (0, _moment.default)(to, INPUT_DATE_TIME_FORMAT) : undefined;

        if (!startDate && !endDate) {
          return this.selectReportPeriod();
        }

        if (startDate && endDate) {
          return this.selectReportPeriod(selectedReportType, startDate, endDate);
        }

        return startDate ? this.selectReportPeriod(selectedReportType, startDate) : this.selectReportPeriod(selectedReportType, endDate);
      },
      subscribeModal: function subscribeModal() {
        this.subscribeModal();
      }
    }
  });

  _exports.default = _default;
});