define("lh4/templates/authenticated/internet-payments/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WTbLD7ch",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"settings\"],[8],[0,\"\\n\\t\"],[7,\"h1\",true],[10,\"class\",\"ui header title-bar\"],[8],[0,\"Settings\"],[9],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"media\",\"isMobile\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"title-bar-menu\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"ui/tab-elements\",[[12,\"class\",\"standard\"]],[[\"@routes\"],[[23,0,[\"routeData\"]]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"ui fluid container\"],[8],[0,\"\\n\\t\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/settings.hbs"
    }
  });

  _exports.default = _default;
});