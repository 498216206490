define("lh4/utils/lbm2", ["exports", "lh4/enums/country-code", "lh4/enums/products", "lh4/enums/services"], function (_exports, _countryCode, _products, _services) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Starlink LBM aka Internet Payments LBM aka LBM2.0 was initially created for "processing-only" merchants
  // and is now being augmented to support additional merchants - i.e. Canada Mozart merchants. This means
  // that we cannot rely only on location.product anymore to determine if we can present this UI
  // There is plan to get rid of internet-payments as location product algotether and simply rely on
  // location services to determine if LBM2.0 is available for a location.
  //
  // Until that happens, frontend is responsible for this augmented heuristic on who can see LBM2.0
  // @see https://shift4.atlassian.net/browse/LH-30678

  /**
   *
   * @param {Object} location model
   * @returns {boolean} whether location can view LBM2
   */
  var canViewLBM2 = function canViewLBM2(location) {
    var _location$services;

    // good old way to determine if location is internet-payments
    if ((location === null || location === void 0 ? void 0 : location.product) === _products.default.INTERNET_PAYMENTS) {
      return true;
    }

    var lbm2Service = location === null || location === void 0 ? void 0 : (_location$services = location.services) === null || _location$services === void 0 ? void 0 : _location$services.find(function (_ref) {
      var name = _ref.name,
          isActive = _ref.isActive;
      return name === _services.default.LBM2 && isActive;
    }); // new specific case for Canada Mozart merchants with LBM2.0 enabled (through Titan)

    if ((location === null || location === void 0 ? void 0 : location.product) === _products.default.MOZART && (location === null || location === void 0 ? void 0 : location.countryCode) === _countryCode.default.CA && lbm2Service) {
      return true;
    }

    return false;
  };

  var _default = canViewLBM2;
  _exports.default = _default;
});