define("lh4/utils/time-validators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidHour = isValidHour;
  _exports.isValidMinute = isValidMinute;

  /**
   * Checks if input value can be consided a valid hour
   * @param {(number|string)} value - input value
   * @param {boolean} isTwelveHourFormat - considers the range in which hour is valid
   * @returns {boolean} whether input value is a valid hour
   */
  function isValidHour(value) {
    var isTwelveHourFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    if (Number.isNaN(Number(value))) {
      return false;
    }

    if (typeof value === 'string' && value.length > 2) {
      return false;
    }

    var numericValue = Number(value);

    if (numericValue < 1) {
      return false;
    }

    if (isTwelveHourFormat && numericValue > 12) {
      return false;
    }

    if (numericValue > 23) {
      return false;
    }

    return true;
  }
  /**
   *
   * @param {(number|string)} value - input value
   * @returns {boolean} whether input value is a valid minute
   */


  function isValidMinute(value) {
    if (Number.isNaN(Number(value))) {
      return false;
    }

    if (typeof value === 'string' && value.length > 2) {
      return false;
    }

    var numericValue = Number(value);

    if (numericValue < 0 || numericValue > 59) {
      return false;
    }

    return true;
  }
});