define("lh4/enums/mlm-report-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MLM_REPORT_TYPES = Object.freeze(['batch-details', 'settlement-enterprise', 'refund', 'chargeback-reconciliation', 'settlement-detail']);
  var _default = MLM_REPORT_TYPES;
  _exports.default = _default;
});