define("lh4/routes/authenticated/epro/timeclock/shifts/create", ["exports", "lh4/routes/general-create-route", "moment"], function (_exports, _generalCreateRoute, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NO_PAYPERIOD_MESSAGE = 'The location must have a pay period before a new shift can be created.' + ' A pay period will be generated the next time you login to the POS terminal.';

  var _default = _generalCreateRoute.default.extend({
    tokenData: Ember.inject.service(),
    modelName: 'timeClockShift',
    childChangesets: {
      timeClockSegments: 'segmentChangesets'
    },
    model: function model() {
      var locationId = this.location.model.id;
      var settings = this.store.peekRecord('echo-pro/setting', locationId);
      return Ember.RSVP.hash({
        settings: settings,
        preferences: this.store.findRecord('preferences', this.tokenData.get('data.sub')),
        timeClockShift: this.store.createRecord('echo-pro/time-clock-shift-v2', {
          locationId: locationId,
          clockedInAt: (0, _moment.default)().seconds(0).toISOString(),
          overtimeCalculation: settings.get('accounting.overtimeCalculation')
        }),
        employees: this.modelFor('authenticated.epro.timeclock.shifts').employees,
        activePayPeriod: this.store.queryRecord('echo-pro/pay-period', {
          order: 'start',
          limit: 1,
          locationId: locationId
        })
      });
    },
    afterModel: function afterModel(model) {
      if (model.activePayPeriod) {
        model.timeClockShift.setProperties({
          payPeriodGuid: model.activePayPeriod.guid,
          payPeriod: model.activePayPeriod,
          payPeriodStartDay: _moment.default.weekdays(model.activePayPeriod.dayOfWeek),
          payPeriodStartedAt: model.activePayPeriod.start
        });
      }
    },
    setupController: function setupController() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var controller = args[0],
          model = args[1];

      this._super.apply(this, args);

      model.timeClockShift.segmentChangesets = controller.changeset.segmentChangesets;
      model.timeClockShift.saveChanges();
      var hasPayPeriod = !!(model.timeClockShift.payPeriodGuid && model.timeClockShift.payPeriod.content);
      controller.setProperties({
        canSave: hasPayPeriod,
        warningMessage: hasPayPeriod ? null : NO_PAYPERIOD_MESSAGE
      });
    }
  });

  _exports.default = _default;
});