define("lh4/templates/authenticated/epro/invoices/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oO8aOd3C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"welcome-page invoices\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\t\\t\"],[7,\"button\",false],[12,\"class\",\"ui basic primary button\"],[12,\"data-test-activate-button\",\"\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"activateInvoices\"]],[8],[0,\"\\n\\t\\t\\tActivate Invoices\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/epro/invoices/landing-page.hbs"
    }
  });

  _exports.default = _default;
});