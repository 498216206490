define("lh4/components/ui/s4/icons/kiosk/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width={{@width}} height={{@height}} viewBox="0 0 14 14" fill="none">
  	<path d="M1 8.5L5.5 8.5L5.5 13C5.5 13.5522 5.94781 14 6.5 14L7.5 14C8.05219 14 8.5 13.5522 8.5 13L8.5 8.5L13 8.5C13.5522 8.5 14 8.05219 14 7.5L14 6.5C14 5.94781 13.5522 5.5 13 5.5L8.5 5.5L8.5 1C8.5 0.447812 8.05219 -5.19974e-07 7.5 -5.68248e-07L6.5 -6.55671e-07C5.94781 -7.03945e-07 5.5 0.447811 5.5 0.999999L5.5 5.5L1 5.5C0.447813 5.5 7.03945e-07 5.94781 6.55671e-07 6.5L5.68248e-07 7.5C5.19974e-07 8.05219 0.447813 8.5 1 8.5Z" fill="#999999"/>
  </svg>
  */
  {
    id: "DjEskFPC",
    block: "{\"symbols\":[\"@width\",\"@height\"],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"width\",[23,1,[]]],[11,\"height\",[23,2,[]]],[10,\"viewBox\",\"0 0 14 14\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M1 8.5L5.5 8.5L5.5 13C5.5 13.5522 5.94781 14 6.5 14L7.5 14C8.05219 14 8.5 13.5522 8.5 13L8.5 8.5L13 8.5C13.5522 8.5 14 8.05219 14 7.5L14 6.5C14 5.94781 13.5522 5.5 13 5.5L8.5 5.5L8.5 1C8.5 0.447812 8.05219 -5.19974e-07 7.5 -5.68248e-07L6.5 -6.55671e-07C5.94781 -7.03945e-07 5.5 0.447811 5.5 0.999999L5.5 5.5L1 5.5C0.447813 5.5 7.03945e-07 5.94781 6.55671e-07 6.5L5.68248e-07 7.5C5.19974e-07 8.05219 0.447813 8.5 1 8.5Z\"],[10,\"fill\",\"#999999\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/kiosk/plus.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});