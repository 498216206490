define("lh4/utils/delay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Delay function to wait for a certain amount of time
   *
   * @param {number} ms
   * @returns {Promise}
   */
  var delay = function delay(ms) {
    return new Promise(function (resolve) {
      return setTimeout(resolve, ms);
    });
  };

  var _default = delay;
  _exports.default = _default;
});