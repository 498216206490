define("lh4/templates/components/internet-payments/apm-services/modals/ach-payments-cancelation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v46VDueW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ach-payments-cancelation-modal\"],[10,\"data-test\",\"ach-underwriting-form\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"warning\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"ui warning message\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[24,[\"warning\",\"message\"]],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"main-title\"],[8],[0,\"\\n\\t\\tCancelation Request\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"cancelation-policy\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"Cancelation policy\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"context\"],[8],[0,\"Shift4 will review all outstanding/inflight ACH request to insure all outstanding requests are cleared. Note - consumer ACH payments have a 60 day right of return.\u2028This will require a bank account reserve for all transactions that are less than 60 days old. Shift4 is able to stop new originating ACH requests at time of cancelation request\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[5,\"ui-checkbox\",[[12,\"data-test\",\"agreedPreconditions\"]],[[\"@onChange\",\"@checked\",\"@label\"],[[28,\"action\",[[23,0,[]],[24,[\"setCheckbox\"]]],null],[22,\"didAgree\"],\"I have read and agree to Terms and Conditions\"]]],[0,\"\\n\\t\"],[7,\"button\",true],[10,\"class\",\"ui primary button\"],[11,\"disabled\",[22,\"isDisabled\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"confirm\"]]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\\t\\tACCEPT AND REQUEST TO DISABLE\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"span\",false],[12,\"class\",\"back\"],[3,\"on\",[\"click\",[24,[\"apmService\",\"toggleCancellationModal\"]]]],[8],[0,\"\\n\\t\\tDiscard and go back to Alternative Payment Methods\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/apm-services/modals/ach-payments-cancelation-modal.hbs"
    }
  });

  _exports.default = _default;
});