define("lh4/constants/launch-control/cards", ["exports", "lh4/constants/launch-control/modules"], function (_exports, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CARD_STATUS = _exports.CARDS_MAP = _exports.SKYTAB_BI_CARD = void 0;

  var _CARDS_MAP;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var INSTALLATION_DETAILS_CARD = {
    title: 'Installation Details',
    required: true,
    imageSrc: '/img/launch-control/carousel/user.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.INSTALLATION_DETAILS_MODULE.name,
    moduleRoute: _modules.INSTALLATION_DETAILS_MODULE.route,
    stepNames: _modules.INSTALLATION_DETAILS_MODULE.stepNames
  };
  var MENU_CARD = {
    title: 'Upload your menu',
    required: true,
    imageSrc: '/img/launch-control/carousel/menu_upload.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.MENU_UPLOAD_MODULE.name,
    moduleRoute: _modules.MENU_UPLOAD_MODULE.route,
    stepNames: _modules.MENU_UPLOAD_MODULE.stepNames
  };
  var RESTAURANTS_BASICS_CARD = {
    title: 'Restaurant Basics',
    required: true,
    imageSrc: '/img/launch-control/carousel/restaurant.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.RESTAURANTS_BASICS_MODULE.name,
    moduleRoute: _modules.RESTAURANTS_BASICS_MODULE.route,
    stepNames: _modules.RESTAURANTS_BASICS_MODULE.stepNames
  };
  var ONLINE_ORDERING_CARD = {
    title: 'Online Ordering',
    required: false,
    imageSrc: '/img/launch-control/carousel/online_ordering.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.ONLINE_ORDERING_MODULE.name,
    moduleRoute: _modules.ONLINE_ORDERING_MODULE.route,
    stepNames: _modules.ONLINE_ORDERING_MODULE.stepNames
  };
  var INTEGRATIONS_V2_CARD = {
    title: 'Finish Online Ordering',
    required: false,
    imageSrc: '/img/launch-control/carousel/online_ordering.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.INTEGRATIONS_MODULE_V2.name,
    moduleRoute: _modules.INTEGRATIONS_MODULE_V2.route,
    stepNames: _modules.INTEGRATIONS_MODULE_V2.stepNames
  };
  var GIFT_CARDS_CARD = {
    title: 'Gift Cards',
    required: false,
    imageSrc: '/img/launch-control/carousel/gift_cards.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.GIFT_CARDS_MODULE.name,
    moduleRoute: _modules.GIFT_CARDS_MODULE.route,
    stepNames: _modules.GIFT_CARDS_MODULE.stepNames
  };
  var LOYALTY_CARD = {
    title: 'Loyalty',
    required: false,
    imageSrc: '/img/launch-control/carousel/loyalty.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.LOYALTY_MODULE.name,
    moduleRoute: _modules.LOYALTY_MODULE.route,
    stepNames: _modules.LOYALTY_MODULE.stepNames
  };
  var SKYTAB_BI_CARD = {
    title: 'Business Intelligence (BI)',
    required: false,
    imageSrc: '/img/launch-control/carousel/skytab_bi.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.SKYTAB_BI_MODULE.name,
    moduleRoute: _modules.SKYTAB_BI_MODULE.route,
    stepNames: _modules.SKYTAB_BI_MODULE.stepNames
  };
  _exports.SKYTAB_BI_CARD = SKYTAB_BI_CARD;
  var FLOOR_PLAN_CARD = {
    title: 'Floor Plan',
    required: false,
    imageSrc: '/img/launch-control/carousel/floor_plan.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.FLOOR_PLAN_MODULE.name,
    moduleRoute: _modules.FLOOR_PLAN_MODULE.route,
    stepNames: _modules.FLOOR_PLAN_MODULE.stepNames
  };
  var RESERVATIONS_CARD = {
    title: 'Reservations',
    required: false,
    imageSrc: '/img/launch-control/carousel/reservations.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.RESERVATIONS_MODULE.name,
    moduleRoute: _modules.RESERVATIONS_MODULE.route,
    stepNames: _modules.RESERVATIONS_MODULE.stepNames
  };
  var WEBSITE_CARD = {
    title: 'Website',
    required: false,
    imageSrc: '/img/launch-control/carousel/website.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.WEBSITE_MODULE.name,
    moduleRoute: _modules.WEBSITE_MODULE.route,
    stepNames: _modules.WEBSITE_MODULE.stepNames
  };
  var EMPLOYEES_CARD = {
    title: 'Employees',
    required: false,
    imageSrc: '/img/launch-control/carousel/employees.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.EMPLOYEES_MODULE.name,
    moduleRoute: _modules.EMPLOYEES_MODULE.route,
    stepNames: _modules.EMPLOYEES_MODULE.stepNames
  };
  var DISCOUNT_CARD = {
    title: 'Discounts and Gratuities',
    required: false,
    imageSrc: '/img/launch-control/discounts.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.DISCOUNT_MODULE.name,
    moduleRoute: _modules.DISCOUNT_MODULE.route,
    stepNames: _modules.DISCOUNT_MODULE.stepNames
  };
  var KIOSK_CARD = {
    title: 'Set Up Kiosk',
    required: false,
    imageSrc: '/img/launch-control/carousel/kiosk.svg',
    iconSrc: '/img/launch-control/chevron_right_blue_24px.png',
    progressSrc: '/img/launch-control/progress.svg',
    isEnabled: true,
    progress: null,
    isSkipped: false,
    moduleName: _modules.KIOSK_MODULE.name,
    moduleRoute: _modules.KIOSK_MODULE.route,
    stepNames: _modules.KIOSK_MODULE.stepNames
  };
  var CARDS_MAP = (_CARDS_MAP = {}, _defineProperty(_CARDS_MAP, EMPLOYEES_CARD.moduleName, EMPLOYEES_CARD), _defineProperty(_CARDS_MAP, WEBSITE_CARD.moduleName, WEBSITE_CARD), _defineProperty(_CARDS_MAP, RESERVATIONS_CARD.moduleName, RESERVATIONS_CARD), _defineProperty(_CARDS_MAP, FLOOR_PLAN_CARD.moduleName, FLOOR_PLAN_CARD), _defineProperty(_CARDS_MAP, SKYTAB_BI_CARD.moduleName, SKYTAB_BI_CARD), _defineProperty(_CARDS_MAP, LOYALTY_CARD.moduleName, LOYALTY_CARD), _defineProperty(_CARDS_MAP, GIFT_CARDS_CARD.moduleName, GIFT_CARDS_CARD), _defineProperty(_CARDS_MAP, ONLINE_ORDERING_CARD.moduleName, ONLINE_ORDERING_CARD), _defineProperty(_CARDS_MAP, RESTAURANTS_BASICS_CARD.moduleName, RESTAURANTS_BASICS_CARD), _defineProperty(_CARDS_MAP, MENU_CARD.moduleName, MENU_CARD), _defineProperty(_CARDS_MAP, INSTALLATION_DETAILS_CARD.moduleName, INSTALLATION_DETAILS_CARD), _defineProperty(_CARDS_MAP, DISCOUNT_CARD.moduleName, DISCOUNT_CARD), _defineProperty(_CARDS_MAP, INTEGRATIONS_V2_CARD.moduleName, INTEGRATIONS_V2_CARD), _defineProperty(_CARDS_MAP, KIOSK_CARD.moduleName, KIOSK_CARD), _CARDS_MAP);
  _exports.CARDS_MAP = CARDS_MAP;
  var CARD_STATUS = {
    PENDING: 'Pending',
    WAITING_ON_MENU: 'Waiting for Menu',
    SKIPPED: 'Skipped',
    COMPLETED: 'Completed',
    IN_PROGRESS: 'In Progress',
    AVAILABLE: 'Available',
    HIDDEN: 'Hidden'
  };
  _exports.CARD_STATUS = CARD_STATUS;
  var _default = CARDS_MAP;
  _exports.default = _default;
});