define("lh4/mirage/routes/internet-payments/settings/business-settings", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsBusinessSettingsServer;

  function setMirageInternetPaymentsBusinessSettingsServer(server) {
    server.post('/batches', function () {
      return new _emberCliMirage.Response(200);
    });
  }
});