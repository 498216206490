define("lh4/templates/authenticated/status-control/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+WDvvWlk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"model\",\"showStatusControl\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",false],[12,\"class\",\"launch-control\"],[12,\"data-dd-action-name\",\"Launch Control\"],[3,\"did-insert\",[[23,0,[\"componentLoaded\"]]]],[8],[0,\"\\n\\t\\t\"],[5,\"launch-control/order-tracker/order-tracker\",[],[[\"@orderStatus\",\"@goLiveDate\",\"@launchControlModules\",\"@setFilter\",\"@selectedFilter\",\"@filters\",\"@cards\",\"@csmInfo\"],[[24,[\"model\",\"orderStatus\"]],[24,[\"model\",\"goLiveDate\"]],[24,[\"model\",\"launchControlModules\"]],[23,0,[\"setFilter\"]],[23,0,[\"selectedFilter\"]],[24,[\"model\",\"filters\"]],[24,[\"model\",\"cards\"]],[24,[\"model\",\"csmInfo\"]]]]],[0,\"\\n\\t\\t\"],[5,\"launch-control/carousel\",[],[[\"@selectedFilter\",\"@cards\",\"@goLiveDate\"],[[23,0,[\"selectedFilter\"]],[24,[\"model\",\"cards\"]],[24,[\"model\",\"goLiveDate\"]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/status-control/index.hbs"
    }
  });

  _exports.default = _default;
});