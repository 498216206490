define("lh4/utils/hash-to-query-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * Build an array of query parameters from the given prefix and value
   *
   * @param {string} prefix - prefix to use when determining key name
   * @param {*} value - value to assign to key name
   * @returns {Array.<string>} array of query parameters
   */
  function buildParams(prefix, value) {
    if (Array.isArray(value)) {
      var useIndex = value.some(function (item) {
        return _typeof(item) === 'object';
      });
      return value.flatMap(function (item, index) {
        return buildParams("".concat(prefix, "[").concat(useIndex ? index : '', "]"), item);
      });
    }

    if (_typeof(value) === 'object') {
      return Object.entries(value).flatMap(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            val = _ref2[1];

        return buildParams("".concat(prefix, "[").concat(key, "]"), val);
      });
    }

    return ["".concat(encodeURIComponent(prefix), "=").concat(encodeURIComponent(value !== null && value !== void 0 ? value : ''))];
  }
  /**
   * Encode a hash of key/values to a query string
   *
   * @param {Object} hash - a hash of key/values
   * @returns {string} query string
   */


  var hashToQueryString = function hashToQueryString(hash) {
    return Object.entries(hash).flatMap(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          key = _ref4[0],
          val = _ref4[1];

      return buildParams(key, val);
    }).join('&');
  };

  var _default = hashToQueryString;
  _exports.default = _default;
});