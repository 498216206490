define("lh4/utils/is-positive-percentage", ["exports", "lh4/utils/is-positive-decimal"], function (_exports, _isPositiveDecimal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PRECISION_WORD = Object.freeze({
    2: 'two',
    3: 'three'
  });
  /**
   * Validate if given value is numeric and between (0, 100]
   *
   * @param {string|number} value - value to validate
   * @returns {boolean|string} - true if value valid, error message otherwise
   */

  var isPositivePercentage = function isPositivePercentage(value) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
    // This util is only set to take in precision of 2 or 3
    // To add more precision options, add string value to PRECISION_WORD and update regex in isPositiveDecimal
    var message;

    if (precision === 0) {
      message = 'Value must be a whole number which is greater than 0 and less than or equal to 100';

      if (!Number.isInteger(value)) {
        return message;
      }
    } else {
      if (precision !== 2 && precision !== 3) {
        // eslint-disable-next-line no-param-reassign
        precision = 2;
      }

      message = "Value must be greater than 0 and less than or equal to 100 and can only contain ".concat(PRECISION_WORD[precision], " decimal numbers");

      if (!(0, _isPositiveDecimal.default)(value, precision)) {
        return message;
      }
    }

    var number = Number(value);
    return number > 0 && number <= 100 ? true : message;
  };

  var _default = isPositivePercentage;
  _exports.default = _default;
});