define("lh4/controllers/authenticated/internet-payments/profile/users", ["exports", "lh4/controllers/general-list-controller", "lh4/enums/ip-pagination-limit"], function (_exports, _generalListController, _ipPaginationLimit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var HEADERS = [{
    field: 'name',
    name: 'Name'
  }, {
    field: 'email',
    name: 'Email'
  }, {
    field: 'status',
    name: 'Status'
  }];
  var USER_STATUSES = ['Active', 'Not Active', 'Not Registered'];

  var _default = _generalListController.default.extend({
    headers: HEADERS,
    queryParams: ['searchTerm', 'statuses', 'locations'],
    media: Ember.inject.service(),
    session: Ember.inject.service(),
    statuses: [].concat(USER_STATUSES),
    locations: undefined,
    searchTerm: undefined,
    order: undefined,
    limit: _ipPaginationLimit.default.LIMIT,
    currentDefaultLocation: null,
    allStatusesSelected: false,
    selectedStatusNames: [].concat(USER_STATUSES),
    allLocationsSelected: false,
    selectedLocationIds: [],
    hasLoadedStatus: false,
    locationOptions: Ember.computed('model.internetPaymentsLocations', 'allLocationsSelected', 'selectedLocationIds.[]', function getter() {
      var _this = this;

      var options = this.model.internetPaymentsLocations.map(function (_ref) {
        var id = _ref.id,
            name = _ref.name;
        return {
          id: id,
          name: name,
          isChecked: _this.allLocationsSelected || _this.selectedLocationIds.includes(id)
        };
      }); // If there is more than 1 option, add the "All" option to the dropdown list.

      if (options.length > 1) {
        var _this$selectedLocatio;

        options.unshift({
          id: null,
          name: 'All',
          isChecked: this.allLocationsSelected || ((_this$selectedLocatio = this.selectedLocationIds) === null || _this$selectedLocatio === void 0 ? void 0 : _this$selectedLocatio.length) === this.model.internetPaymentsLocations.length
        });
      }

      return options;
    }),
    locationCheckboxPreview: Ember.computed('selectedLocationIds', 'locations', function getter() {
      var _this$locations;

      var locs = (_this$locations = this.locations) !== null && _this$locations !== void 0 ? _this$locations : this.selectedLocationIds;
      var locationsCount = locs.length;
      var internetPaymentsLocations = this.model.internetPaymentsLocations;

      if (internetPaymentsLocations.length > 1 && locationsCount === internetPaymentsLocations.length) {
        return 'All';
      }

      if (locationsCount > 2) {
        return "".concat(locationsCount, " Selected");
      }

      return this.locationOptions.filter(function (_ref2) {
        var id = _ref2.id;
        return locs.includes(id);
      }).map(function (_ref3) {
        var name = _ref3.name;
        return name;
      }).join(', ');
    }),
    statusOptions: Ember.computed('allStatusesSelected', 'selectedStatusNames.[]', function getter() {
      var _this2 = this;

      var optionsArr = this.selectedStatusNames;

      if (!this.hasLoadedStatus) {
        optionsArr = this.statuses;
        this.hasLoadedStatus = true;
      }

      var options = USER_STATUSES.map(function (name) {
        return {
          name: name,
          isChecked: _this2.allStatusesSelected || optionsArr.includes(name)
        };
      });
      options.unshift({
        name: 'All',
        isChecked: this.allStatusesSelected || optionsArr.length === USER_STATUSES.length
      });
      return options;
    }),
    statusOptionsPreview: Ember.computed('statuses', function getter() {
      var _this$statuses$length, _this$statuses;

      var statusOptionCount = (_this$statuses$length = (_this$statuses = this.statuses) === null || _this$statuses === void 0 ? void 0 : _this$statuses.length) !== null && _this$statuses$length !== void 0 ? _this$statuses$length : 0;

      if (statusOptionCount === USER_STATUSES.length) {
        return 'All';
      }

      if (statusOptionCount > 2) {
        return "".concat(statusOptionCount, " Selected");
      }

      return this.statuses.join(', ');
    }),
    resetButtonClass: Ember.computed('locations', 'statuses', 'currentDefaultLocation', 'allStatusesSelected', function getter() {
      return (this.locations === undefined || this.locations.length === 1 && this.locations[0] === this.currentDefaultLocation) && (this.statuses === undefined || this.statuses.length === USER_STATUSES.length && this.statuses.every(function (val, index) {
        return val === USER_STATUSES[index];
      })) ? 'disabled' : '';
    }),
    selectAllLocations: function selectAllLocations() {
      this.setProperties({
        allLocationsSelected: true,
        selectedLocationIds: this.locationOptions.slice(1).map(function (_ref4) {
          var id = _ref4.id;
          return id;
        })
      });
    },
    unselectAllLocations: function unselectAllLocations() {
      this.setProperties({
        allLocationsSelected: false,
        selectedLocationIds: []
      });
    },
    selectAllStatuses: function selectAllStatuses() {
      this.setProperties({
        allStatusesSelected: true,
        selectedStatusNames: [].concat(USER_STATUSES)
      });
    },
    unselectAllStatuses: function unselectAllStatuses() {
      this.setProperties({
        allStatusesSelected: false,
        selectedStatusNames: []
      });
    },
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('searchTerm', search);
        this.set('offset', 0);
      },
      onRowClick: function onRowClick(record) {
        this.transitionToRoute('authenticated.internet-payments.profile.users.edit', record.id);
      },
      onSelectStatusCheckboxes: function onSelectStatusCheckboxes(name, isChecked) {
        if (name === 'All') {
          if (isChecked) {
            this.selectAllStatuses();
            return;
          }

          this.unselectAllStatuses();
          return;
        }

        if (isChecked) {
          this.selectedStatusNames.pushObject(name);

          if (this.selectedStatusNames.length === this.statusOptions.length - 1) {
            this.selectAllStatuses();
          }
        } else {
          this.setProperties({
            allStatusesSelected: false,
            selectedStatusNames: this.selectedStatusNames.filter(function (statusName) {
              return statusName !== name;
            })
          });
        }
      },
      onSelectLocationCheckboxes: function onSelectLocationCheckboxes(locationOption, isChecked) {
        var name = locationOption.name,
            id = locationOption.id;

        if (name === 'All') {
          if (isChecked) {
            this.selectAllLocations();
            return;
          }

          this.unselectAllLocations();
          return;
        }

        if (isChecked) {
          this.selectedLocationIds.pushObject(id);

          if (this.selectedLocationIds.length === this.locationOptions.length - 1) {
            this.selectAllLocations();
          }
        } else {
          this.setProperties({
            allLocationsSelected: false,
            selectedLocationIds: this.selectedLocationIds.filter(function (locationId) {
              return locationId !== id;
            })
          });
        }
      },
      saveStatusSettings: function saveStatusSettings() {
        this.set('statuses', _toConsumableArray(this.selectedStatusNames));
      },
      saveLocationSettings: function saveLocationSettings() {
        this.set('locations', _toConsumableArray(this.selectedLocationIds));
      },
      onStatusesHide: function onStatusesHide() {
        if (this.statuses) {
          this.set('selectedStatusNames', _toConsumableArray(this.statuses));
          this.set('allStatusesSelected', false);
        }
      },
      onLocationsHide: function onLocationsHide() {
        if (this.locations) {
          this.set('selectedLocationIds', _toConsumableArray(this.locations));
          this.set('allLocationsSelected', false);
        }
      },
      clearStatusSettings: function clearStatusSettings() {
        this.unselectAllStatuses();
        this.setProperties({
          statuses: []
        });
      },
      clearLocationSettings: function clearLocationSettings() {
        this.unselectAllLocations();
        this.setProperties({
          locations: []
        });
      },
      resetAllSettings: function resetAllSettings() {
        this.setProperties({
          allStatusesSelected: true,
          statuses: [].concat(USER_STATUSES),
          selectedStatusNames: [].concat(USER_STATUSES),
          allLocationsSelected: false,
          locations: [this.currentDefaultLocation],
          selectedLocationIds: [this.currentDefaultLocation]
        });
      },
      create: function create() {
        this.transitionToRoute('authenticated.internet-payments.profile.users.create');
      }
    }
  });

  _exports.default = _default;
});