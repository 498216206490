define("lh4/adapters/echo-pro/pay-period", ["exports", "lh4/adapters/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'api/v2',
    urlForQueryRecord: function urlForQueryRecord() {
      var _query$locationId;

      var query = arguments.length <= 0 ? undefined : arguments[0];
      var location = this.get('location'); // Get locationId either from query or from current location.

      var locationId = (_query$locationId = query.locationId) !== null && _query$locationId !== void 0 ? _query$locationId : location.model.id; // If guid is passed, add on to url. Otherwise, don't add it.

      var guid = query.guid ? "/".concat(query.guid) : '';
      delete query.locationId;
      delete query.guid;
      return "".concat(this._super.apply(this, arguments), "/").concat(locationId).concat(guid);
    }
  });

  _exports.default = _default;
});