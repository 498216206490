define("lh4/templates/authenticated/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HdAM8B8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui active inverted dimmer\"],[8],[0,\"\\n\\t\"],[5,\"ui/s4/loaders/s4-loader\",[],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/loading.hbs"
    }
  });

  _exports.default = _default;
});