define("lh4/constants/snackbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TYPE = void 0;
  var TYPE = Object.freeze({
    ALERT: 'alert',
    SUCCESS: 'success',
    INFO: 'info'
  });
  _exports.TYPE = TYPE;
  var _default = TYPE;
  _exports.default = _default;
});