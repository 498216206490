define("lh4/enums/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SERVICES = Object.freeze({
    FINARO_INSIGHT: 'finaro-insight',
    LBM2: 'lbm2',
    MATTERHORN: 'matterhorn-api'
  });
  var _default = SERVICES;
  _exports.default = _default;
});