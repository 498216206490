define("lh4/controllers/authenticated/loyalty/members/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    isEditing: true,
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    handleAdjustTransError: function handleAdjustTransError(errors) {
      var _errors$,
          _this = this;

      // TODO normalize how we handle these error messages
      var updateError = 'Something went wrong, adjust transaction could not be saved';
      var errorMessage = ((_errors$ = errors[0]) === null || _errors$ === void 0 ? void 0 : _errors$.message) || updateError;
      this.set('serverError', errorMessage);
      this.set('serverErrorTimeoutId', Ember.run.later(this, function () {
        _this.set('serverError', null);
      }, this.serverErrorTimeoutDelay));
    },
    trackUpdate: function trackUpdate() {
      this.metrics.trackEvent('MixpanelCustom', {
        event: 'Loyalty Members Update Completed',
        'Entry Type': 'Edit',
        Status: this.changeset.get('isActive')
      });
    },
    actions: {
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      update: function update() {
        var _this2 = this;

        if (this.isSaving) {
          return;
        }

        this.set('showErrors', true);
        this.set('isSaving', true);
        this.clearServerError(); // we have to check for pristine before we validate and save

        var isPristine = this.isModelAndChangesetPristine() && this.areChildrenPristine(this.model.member.transactions, this.changeset.get('addAdjustmentTransactionChangesets'));
        var pendingAdjustmentTransaction = this.changeset.get('addAdjustmentTransactionChangesets')[0];
        this.changeset.validate().then(function () {
          if (!_this2.changeset.get('isValid')) {
            return Ember.RSVP.reject(_this2.changeset.get('errors'));
          }

          if (isPristine) {
            _this2.closeTray();

            return Ember.RSVP.resolve();
          }

          if (pendingAdjustmentTransaction) {
            return pendingAdjustmentTransaction.validate().then(function () {
              if (!pendingAdjustmentTransaction.isValid) {
                _this2.set('areChildrenInvalid', true);

                return Ember.RSVP.reject(pendingAdjustmentTransaction.get('errors'));
              }

              return Ember.RSVP.resolve();
            });
          }

          return Ember.RSVP.resolve();
        }).then(function () {
          _this2.changeset.set('addAdjustmentTransactionChangesets', []);

          return _this2.changeset.save().then(function () {
            if (pendingAdjustmentTransaction) {
              return pendingAdjustmentTransaction.save().then(function () {
                pendingAdjustmentTransaction.rollback();
              });
            }

            return Ember.RSVP.resolve();
          }).then(function () {
            _this2.trackUpdate();

            Ember.RSVP.resolve(_this2.closeTray());
          }).catch(function (_ref) {
            var errors = _ref.errors;

            _this2.handleAdjustTransError(errors);
          }).catch(function (_ref2) {
            var errors = _ref2.errors;

            if (!(errors && errors[0])) {
              return;
            }

            _this2.changeset.data.rollbackAttributes(); // TODO normalize how we handle these error messages


            var message = "Something went wrong, ".concat(_this2.instanceName, " could not be updated.");
            errors.forEach(function (error) {
              var errorMessage = error.details || error.message || message;

              _this2.set('serverError', errorMessage);

              _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                _this2.set('serverError', null);
              }, _this2.serverErrorTimeoutDelay));
            });
          });
        }).catch(function (errors) {
          // We currently don't have a field component for checkboxes
          // Because of this there is no standard way of displaying
          // error messages for boolean fields
          // https://jira.shift4payments.com/browse/LH-8853
          if (!errors.length) {
            return;
          }

          var booleanError = errors.find(function (error) {
            return typeof error.value === 'boolean';
          });

          if (booleanError) {
            _this2.set('booleanError', booleanError.validation);

            _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
              _this2.set('booleanError', null);
            }, _this2.serverErrorTimeoutDelay));
          }
        }).finally(function () {
          return _this2.setProperties({
            isSaving: false
          });
        });
      }
    }
  });

  _exports.default = _default;
});