define("lh4/components/launch-control/online-ordering/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui active inverted dimmer">
  	<Ui::S4::Loaders::S4Loader />
  </div>
  
  */
  {
    id: "QRB9V+VW",
    block: "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui active inverted dimmer\"],[8],[0,\"\\n\\t\"],[5,\"ui/s4/loaders/s4-loader\",[],[[],[]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/launch-control/online-ordering/loading.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});