define("lh4/models/echo-pro/discount", ["exports", "ember-data", "ember-data/attr", "ember-cp-validations", "lh4/utils/boolean-property-array", "moment", "lh4/utils/is-positive-decimal-of-length", "lh4/utils/is-positive-percentage"], function (_exports, _emberData, _attr, _emberCpValidations, _booleanPropertyArray, _moment, _isPositiveDecimalOfLength, _isPositivePercentage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember, _Ember2;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    value: {
      description: 'Value',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), // The following is a list of the actual value of each mode:
      // Mode 0 = Amount Off($) with a maximum of 9 digits with a 2 digit precision
      // Mode 1 = Percentage Off(%) can't be over 100.00
      // Mode 2 = Forced Price($) with a maximum of 9 digits with a 2 digit precision
      (0, _emberCpValidations.validator)(function (value, options, model) {
        return parseInt(model.get('mode'), 10) === 1 ? (0, _isPositivePercentage.default)(value) : (0, _isPositiveDecimalOfLength.default)(value, 9);
      }, {
        dependentKeys: ['model.mode']
      })]
    },
    startDate: {
      description: 'Start Date',
      validators: [(0, _emberCpValidations.validator)('date', {
        onOrBefore: Ember.computed.alias('model.endDate'),
        disabled: Ember.computed.alias('model.isAllowedAnyDate')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isAllowedAnyDate')
      })]
    },
    endDate: {
      description: 'End Date',
      validators: [(0, _emberCpValidations.validator)('date', {
        onOrAfter: Ember.computed.alias('model.startDate'),
        disabled: Ember.computed.alias('model.isAllowedAnyDate')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isAllowedAnyDate')
      })]
    },
    startTime: {
      description: 'Start Time',
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        var startTime = (0, _moment.default)(value);
        var endTime = (0, _moment.default)(model.get('endTime'));

        if (startTime.isAfter(endTime)) {
          return 'Start Time must be less than End Time';
        }

        return true;
      }, {
        dependentKeys: ['model.endTime'],
        disabled: Ember.computed.alias('model.isAllowedAnyTime')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isAllowedAnyTime')
      })]
    },
    endTime: {
      description: 'End Time',
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        var startTime = (0, _moment.default)(model.get('startTime'));
        var endTime = (0, _moment.default)(value);

        if (endTime.isBefore(startTime)) {
          return 'End Time must be greater than Start Time';
        }

        return true;
      }, {
        dependentKeys: ['model.startTime'],
        disabled: Ember.computed.alias('model.isAllowedAnyTime')
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.alias('model.isAllowedAnyTime')
      })]
    },
    isAppliedTo: (0, _emberCpValidations.validator)('presence', {
      message: 'Discount must apply to at least one option',
      presence: true
    }),
    isAutomatic: {
      description: 'Apply Discount automatically',
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        if (value && model.get('isAppliedTo').includes('isAppliedToTickets')) {
          return 'Apply Discount automatically cannot be applied to Tickets';
        }

        return true;
      }, {
        dependentKeys: ['model.isAppliedTo']
      })]
    },
    couponCode: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      disabled: Ember.computed.not('model.isCouponCodeEnabled')
    }), (0, _emberCpValidations.validator)('length', {
      message: 'Coupon Code must be a minimum of 3 and a maximum of 20 characters',
      min: 3,
      max: 20
    }), (0, _emberCpValidations.validator)(function (value) {
      var reg = /[^A-Za-z0-9]+/;

      if (!reg.test(value)) {
        return true;
      }

      return 'Coupon Code can only contain letters and numbers.  No spaces or any other special characters.';
    })],
    displayName: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true,
      disabled: Ember.computed.not('model.isCouponCodeEnabled')
    }), (0, _emberCpValidations.validator)('length', {
      message: 'Coupon name length cannot be longer then 255 characters long',
      min: 1,
      max: 255
    })]
  });
  var IS_APPLIED_TO_ATTRIBUTES = {
    isAppliedToItems: (0, _attr.default)('boolean'),
    isAppliedToTickets: (0, _attr.default)('boolean')
  };
  var WEEKDAY_ATTRIBUTES = {
    isAllowedSunday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedMonday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedTuesday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedWednesday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedThursday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedFriday: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedSaturday: (0, _attr.default)('boolean', {
      defaultValue: true
    })
  };

  var _default = _emberData.default.Model.extend(Validations, IS_APPLIED_TO_ATTRIBUTES, WEEKDAY_ATTRIBUTES, {
    startDate: (0, _attr.default)('string'),
    endDate: (0, _attr.default)('string'),
    startTime: (0, _attr.default)('time'),
    endTime: (0, _attr.default)('time'),
    guid: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number'),
    isAutomatic: (0, _attr.default)('boolean'),
    name: (0, _attr.default)('string'),
    // 0 - as amount
    // 1 - as percent
    // 2 - as force price
    mode: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    value: (0, _attr.default)('string'),
    isAllowedAnyDate: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isAllowedAnyTime: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isRepeatedYearly: (0, _attr.default)('boolean'),
    isAppliedToModifiers: (0, _attr.default)('boolean'),
    isReasonRequired: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isReasonNumeric: (0, _attr.default)('boolean'),
    isCalculatedPostTax: (0, _attr.default)('boolean'),
    orderTypeGuid: (0, _attr.default)('string'),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    items: (0, _attr.default)({
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isAppliedTo: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(IS_APPLIED_TO_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(IS_APPLIED_TO_ATTRIBUTES))])),
    weekdays: (_Ember2 = Ember).computed.apply(_Ember2, _toConsumableArray(Object.keys(WEEKDAY_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(WEEKDAY_ATTRIBUTES))])),
    couponCode: (0, _attr.default)('string'),
    displayName: (0, _attr.default)('string', {
      defaultValue: null
    }),
    isCouponCodeEnabled: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isLoyaltyDiscount: (0, _attr.default)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});