define("lh4/services/permissions", ["exports", "lh4/utils/permissions"], function (_exports, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.services = void 0;

  function matchLocation(locationId) {
    return function (x) {
      return Number(x.l, 10) === Number(locationId) || Number(x.locationId, 10) === Number(locationId);
    };
  }

  var matchPermission = function matchPermission(id) {
    return function (x) {
      return x.p === id || x.userPermissionId === id;
    };
  };

  var services = Object.freeze({
    reservations: 1,
    'online-ordering': 2,
    tabbedout: 3,
    'gift-cards': 4,
    harborpay: 5,
    'lighthouse-premium': 6,
    tableside: 7,
    'lighthouse-loyalty': 8
  });
  _exports.services = services;

  var _default = Ember.Service.extend({
    location: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    activateService: function activateService(name) {
      var _this = this;

      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('location.model');
      var serviceId = services[name];
      return this.ajax.request("/api/v1/services/location/".concat(location.id)).then(function (locationServices) {
        var locationService = locationServices.services.findBy('name', name);

        if (locationService) {
          return _this.ajax.request("/api/v1/services/location/".concat(location.id), {
            method: 'PUT',
            data: JSON.stringify({
              serviceId: serviceId,
              isActive: true,
              configuration: locationService.configuration
            })
          });
        }

        return _this.ajax.request("/api/v1/services/location/".concat(location.id), {
          method: 'POST',
          data: JSON.stringify({
            serviceId: serviceId
          })
        });
      }).then(function () {
        return _this.refresh();
      });
    },
    getService: function getService(name) {
      var _location$services;

      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.location.model;
      return location === null || location === void 0 ? void 0 : (_location$services = location.services) === null || _location$services === void 0 ? void 0 : _location$services.find(function (x) {
        return x.name === name;
      });
    },
    hasService: function hasService(name) {
      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.location.model;
      var locationService = this.getService(name, location);
      return locationService && locationService.locationService.isActive;
    },
    hasProduct: function hasProduct(product) {
      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('location.model');
      return location && location.get('product') === product;
    },
    hasPermission: function hasPermission(permission) {
      var locationId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('location.model.id');
      return (locationId ? this.get('tokenData.data').permissions.filter(matchLocation(locationId)) : this.get('tokenData.data').permissions).some(matchPermission(permission));
    },
    getLocationPermissions: function getLocationPermissions() {
      var locationId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('location.model.id');
      return this.get('tokenData.data').permissions.filter(matchLocation(locationId)).map(function (x) {
        return x.p || x.userPermissionId;
      });
    },
    isOwner: Ember.computed('tokenData.data', function isOwner() {
      return !this.get('tokenData.data.parentUserId');
    }),
    refresh: function refresh() {
      var _this2 = this;

      var locationId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('location.model.id');

      if (!locationId) {
        return false;
      }

      return this.store.findRecord('location', locationId).then(function () {
        // notifyPropertyChange is being used instead of this.set('location.model', location) that seemed
        // to not do anything
        // The guess is that it wasn't doing anything because we were setting it to the same object reference
        // Thus observable engine didn't catch any deeply nested property changes
        // This forces various dependent properties to recompute, i.e. computedAbility
        _this2.location.notifyPropertyChange('model');
      });
    },
    reportLocations: Ember.computed('tokenData.data', function reportLocations() {
      return this.get('tokenData.data').permissions.filter(matchPermission(_permissions.default.GENERAL.REPORTS)).map(function (x) {
        return x.l || x.locationId;
      });
    }),
    reportGiftCardLocations: Ember.computed('tokenData.data', function reportGiftCardLocations() {
      return this.get('tokenData.data').permissions.filter(matchPermission(_permissions.default.REPORTING.GIFT_CARDS)).map(function (x) {
        return x.l || x.locationId;
      });
    })
  });

  _exports.default = _default;
});