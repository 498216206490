define("lh4/components/ui/s4/icons/kiosk/minus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width={{@width}} height={{@height}} viewBox="0 0 16 16" fill="none">
  	<path d="M14 6.5H2C1.44781 6.5 1 6.94781 1 7.5V8.5C1 9.05219 1.44781 9.5 2 9.5H14C14.5522 9.5 15 9.05219 15 8.5V7.5C15 6.94781 14.5522 6.5 14 6.5Z" fill="#999999"/>
  </svg>
  */
  {
    id: "EyRF6iK5",
    block: "{\"symbols\":[\"@width\",\"@height\"],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"width\",[23,1,[]]],[11,\"height\",[23,2,[]]],[10,\"viewBox\",\"0 0 16 16\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M14 6.5H2C1.44781 6.5 1 6.94781 1 7.5V8.5C1 9.05219 1.44781 9.5 2 9.5H14C14.5522 9.5 15 9.05219 15 8.5V7.5C15 6.94781 14.5522 6.5 14 6.5Z\"],[10,\"fill\",\"#999999\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/kiosk/minus.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});