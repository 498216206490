define("lh4/mirage/routes/s4-invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInvoicesServer;

  function setMirageInvoicesServer(server) {
    server.get('/s4-invoices/app/:locationId/onboarding', function () {
      return {
        status: 'success'
      };
    });
  }
});