define("lh4/controllers/authenticated/epro/invoices/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      activateInvoices: function activateInvoices() {
        this.transitionToRoute('authenticated.epro.invoices.sso');
      }
    }
  });

  _exports.default = _default;
});