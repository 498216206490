define("lh4/enums/products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PRODUCT = Object.freeze({
    EW: 'echo-pro',
    // A.K.A echo-pro|epro|hbr|edgewater|st-pos. EW stands for EdgeWater
    ECHO: 'echo',
    ELITE: 'elite',
    GPATT: 'gpatt',
    // A.K.A SkyTab
    HARBORPAY: 'harborpay',
    HOSPITALITY: 'hospitality',
    INTERNET_PAYMENTS: 'internet-payments',
    ISV: 'isv',
    LIGHTHOUSE: 'lighthouse',
    LOYALTY: 'loyalty',
    MOZART: 'mozart',
    PROCESSING: 'processing',
    // A.K.A terminal
    QSR: 'qsr',
    RETAIL: 'retail',
    SALON: 'salon',
    SOLO: 'solo',
    TERMINAL: 'terminal',
    // A.K.A processing
    PROCESSING_FINARO: 'processing-finaro',
    PRODUCT: 'product' // This is probably not a real product, but it's one of the enum values in report subscriptions DB table

  });
  var _default = PRODUCT;
  _exports.default = _default;
});