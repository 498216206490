define("lh4/controllers/authenticated/dashboard", ["exports", "jquery", "lh4/enums/date-range-title", "lh4/utils/period-to-moment"], function (_exports, _jquery, _dateRangeTitle, _periodToMoment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    metrics: Ember.inject.service(),
    terminalsOnly: Ember.computed('model.locations', function terminalsOnly() {
      return this.get('model.locations').toArray().every(function (location) {
        return location.get('product') === 'terminal';
      });
    }),
    currentProcessingDetail: Ember.observer('location.model', function currentProcessingDetail() {
      var period = (0, _dateRangeTitle.isCurrentBusinessDay)(this.model.preferences.currentOrLastDay) ? _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT : _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST;
      var processingDates = (0, _periodToMoment.default)(period);
      this.getBatchDetail(this.location.model.merchantId, processingDates.startsAt, processingDates.endsAt);
    }),
    batchDetailLoading: false,
    processingStartsAt: null,
    processingEndsAt: null,
    processingPeriodSelected: null,
    financialStartsAt: null,
    financialEndsAt: null,
    financialPeriodSelected: null,
    financialLoading: {
      grossSales: false,
      netSales: false,
      taxes: false,
      voids: false,
      cashPayments: false,
      creditCardPayments: false,
      discounts: false
    },
    financialReportPeriod: [// DATE_RANGE_TITLE.CURRENT,
    _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT, _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST, _dateRangeTitle.DATE_RANGE_TITLE.WEEK.TO_DATE, _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST, _dateRangeTitle.DATE_RANGE_TITLE.MONTH.TO_DATE, _dateRangeTitle.DATE_RANGE_TITLE.MONTH.LAST, _dateRangeTitle.DATE_RANGE_TITLE.YEAR.TO_DATE, _dateRangeTitle.DATE_RANGE_TITLE.YEAR.LAST],
    processingReportPeriod: [_dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT, _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST, _dateRangeTitle.DATE_RANGE_TITLE.WEEK.TO_DATE, _dateRangeTitle.DATE_RANGE_TITLE.WEEK.LAST, _dateRangeTitle.DATE_RANGE_TITLE.MONTH.TO_DATE, _dateRangeTitle.DATE_RANGE_TITLE.MONTH.LAST],
    getBatchDetail: function getBatchDetail(merchantId, startsAt, endsAt) {
      var _this = this;

      // TODO:: This check should be removed after LH-17337 finished.
      if (this.location.canViewLBM2) {
        return;
      }

      this.set('batchDetailLoading', true);
      this.store.unloadAll('processing/batch');
      this.store.unloadAll('processing/deposit');
      this.store.unloadAll('processing/transaction');
      this.setProperties({
        processingStartsAt: startsAt,
        processingEndsAt: endsAt
      });
      this.store.queryRecord('dashboard/processing/batch-detail', {
        merchantId: merchantId,
        start: startsAt.toISOString(),
        end: endsAt.toISOString()
      }).finally(function () {
        return _this.set('batchDetailLoading', false);
      });
    },
    getModelsToLoad: function getModelsToLoad() {
      var models = [];

      if (this.can.can('view sales info in general')) {
        models.push('grossSales', 'netSales', 'taxes', 'discounts');
      }

      if (this.can.can('view payments info in general')) {
        models.push('voids', 'cashPayments', 'creditCardPayments');
      }

      if (this.can.can('view tickets info in general')) {
        models.push('openTickets');
      }

      return models;
    },
    getFinancialData: function getFinancialData(startsAt, endsAt) {
      var _this2 = this;

      this.setProperties({
        financialStartsAt: startsAt,
        financialEndsAt: endsAt
      });
      var query = {
        start: startsAt.toISOString(),
        end: endsAt.toISOString()
      };
      this.getModelsToLoad().forEach(function (modelName) {
        _this2.set("financialLoading.".concat(modelName), true);

        _this2.set("model.".concat(modelName), _this2.store.query("dashboard/financial-overview/".concat(Ember.String.dasherize(modelName)), query)).finally(function () {
          return _this2.set("financialLoading.".concat(modelName), false);
        });
      });
    },
    actions: {
      removeWarning: function removeWarning() {
        window.localStorage.setItem('hideDashboardWarning', 'true');
        (0, _jquery.default)('.dashboard-warning').transition('fade');
      },
      selectProcessingPeriod: function selectProcessingPeriod(value) {
        if (this.processingPeriodSelected !== value) {
          this.set('processingPeriodSelected', value);
          var dates = (0, _periodToMoment.default)(value);
          this.getBatchDetail(this.get('location.model.merchantId'), dates.startsAt, dates.endsAt);
        }
      },
      selectFinancialPeriod: function selectFinancialPeriod(value) {
        if (this.financialPeriodSelected !== value) {
          this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
            eventCategory: 'old-dashboard',
            eventAction: 'period-select',
            eventLabel: value
          });
          this.set('financialPeriodSelected', value);
          var dates = (0, _periodToMoment.default)(value, this.get('model.preferences.eod'), this.get('model.preferences.weekStart'));
          this.getFinancialData(dates.startsAt, dates.endsAt);
        }
      },
      toggleOldDashboard: function toggleOldDashboard() {
        this.set('model.preferences.oldDashboard', false);
        this.get('model.preferences').save();
        this.transitionToRoute('authenticated.dashboard2');
      }
    }
  });

  _exports.default = _default;
});