define("lh4/utils/time-converter", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.adjustToUTC = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   *
   * @param {string} time24 - time string in 24h format, i.e. '23:34'
   * @returns {Object} 12h time format object in LT format, i.e. '11:34 PM'
   */
  var convertFrom24To12Format = function convertFrom24To12Format(time24) {
    var _time24$match$slice = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1),
        _time24$match$slice2 = _slicedToArray(_time24$match$slice, 2),
        sHours = _time24$match$slice2[0],
        minutes = _time24$match$slice2[1];

    var meridiemIndicator = +sHours < 12 ? 'AM' : 'PM';
    var hours = +sHours % 12 || 12;
    return {
      hours: hours,
      minutes: parseInt(minutes, 10),
      meridiemIndicator: meridiemIndicator
    };
  };
  /**
   * Adjusts a given time to UTC while treating it as if it's local in the specified timezone.
   *
   * @param {string} time - The time to be adjusted, in the format 'YYYY-MM-DDTHH:mm:ss.SSS'.
   * @param {string} timezone - The IANA timezone identifier (e.g., 'America/New_York').
   * @returns {string} - The adjusted time in UTC, formatted as 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'.
   */


  var adjustToUTC = function adjustToUTC(time, timezone) {
    var tzTime = _moment.default.tz(time, 'YYYY-MM-DDTHH:mm:ss.SSS', 'UTC').tz(timezone, true);

    return tzTime.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  };

  _exports.adjustToUTC = adjustToUTC;
  var _default = convertFrom24To12Format;
  _exports.default = _default;
});